import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'

export const WorkspaceThemeContext = createContext<{
  primaryColor: string
  setPrimaryColor: Dispatch<SetStateAction<string>> | (() => void)
  secondaryColor: string
  setSecondaryColor: Dispatch<SetStateAction<string>> | (() => void)
  tertiaryColor: string
  setTertiaryColor: Dispatch<SetStateAction<string>> | (() => void)
}>({
  primaryColor: '#272b34',
  setPrimaryColor: () => {},
  secondaryColor: '#ff6138',
  setSecondaryColor: () => {},
  tertiaryColor: '#663837',
  setTertiaryColor: () => {},
})

export const WorkspaceThemeProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { settings } = useAppSelector(selectWorkspace)

  const [primaryColor, setPrimaryColor] = useState(settings?.primary_color || '#272b34')
  const [secondaryColor, setSecondaryColor] = useState(settings?.secondary_color || '#ff6138')
  const [tertiaryColor, setTertiaryColor] = useState(settings?.tertiary_color || '#663837')

  useEffect(() => {
    if (settings) {
      setPrimaryColor(settings.primary_color || '#272b34')
      setSecondaryColor(settings.secondary_color || '#ff6138')
      setTertiaryColor(settings.tertiary_color || '#663837')
    }
  }, [settings])

  return (
    <WorkspaceThemeContext.Provider
      value={{ primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor, tertiaryColor, setTertiaryColor }}>
      {children}
    </WorkspaceThemeContext.Provider>
  )
}
