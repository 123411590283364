import { Orbit, Types } from '@orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { uploadFileToUrl } from '@utils/functions/helperFunctions'
import { ApiResponseError } from '@utils/types/auth'
import { iImporterInitialState } from '@utils/types/import'
import { iUserInitialState } from '@utils/types/user'

const initialState: iImporterInitialState = {
  importers: null,
  importData: null,
  status: 'idle',
  importersMeta: null,
  processedDataMeta: null,
}

export const listImporters = createAsyncThunk(
  'users/listImporters',
  async ({ page }: { page: string }, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.importService.listImporters({ page })
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const readImporter = createAsyncThunk(
  'users/readImporter',
  async ({ importerId, page }: { importerId: string; page: string }, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.importService.readImporter(importerId, { page })
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const generateImportUserFileUploadUrl = createAsyncThunk(
  'users/generateImportUserFileUploadUrl',
  async ({ eid, files, type }: { eid?: string; files: File[]; type: string }, { rejectWithValue }): Promise<any> => {
    try {
      const file_names = files.map(({ name }) => name)
      const generateURLResponse = await Orbit.Services.importService.generateFileUploadUrl({ eid, file_names, type })

      await Promise.all(
        files.map(async (file, index) => {
          const res = await uploadFileToUrl(file, generateURLResponse.data[index].upload_presign_url)
        })
      )

      const response = await Orbit.Services.importService.createImporter({
        type: 'USER_INVITE',
        files: generateURLResponse.data.map(({ eid }) => eid),
      })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const startImporter = createAsyncThunk(
  'users/startImporter',
  async (importerId: string, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.importService.startImporter(importerId)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const importerSlice = createSlice({
  name: 'importer',
  initialState,
  reducers: {
    resetImporterSlice: () => initialState,
    resetImportData: (state) => {
      state.importData = initialState.importData
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listImporters.fulfilled, (state, action) => {
        state.importers = action.payload.data
        state.importersMeta = action.payload.meta
        state.status = 'success'
      })
      .addCase(listImporters.rejected, (state) => {
        state.status = 'failed'
      })
      .addMatcher(isAnyOf(listImporters.pending, readImporter.pending), (state) => {
        state.status = 'loading'
      })
      .addMatcher(isAnyOf(startImporter.fulfilled, readImporter.fulfilled, readImporter.rejected), (state, action) => {
        state.importData = action.payload.data
        state.processedDataMeta = action.payload.meta
        state.status = 'success'
        // state.importData = {
        //     eid: 'IM-4DE3-N63G-YZCO',
        //     name: 'import-USER_INVITE-2023-12-13_06:36:59',
        //     type: 'USER_INVITE',
        //     status: 'PROCESSING_COMPLETE',
        //     created_at: '2023-12-13T06:36:59.000000Z',
        //     updated_at: '2023-12-13T06:37:00.000000Z',
        //     'import-data': [
        //         {
        //             row_number: 2,
        //             data: {
        //                 name: 'Simon Davies',
        //                 email: 'test@example.com.au',
        //             },
        //         },
        //         {
        //             row_number: 3,
        //             data: {
        //                 name: 'Nipuna Herath Herath',
        //                 email: 'test2@example.com.au',
        //             },
        //         },
        //     ],
        //     files: [
        //         {
        //             eid: 'FI-4DE3-N28L-M90V',
        //             file_format: 'csv',
        //             original_file_name: 'import.csv',
        //             initial_file_type: 'IMPORT_FILE',
        //             is_private: true,
        //         },
        //     ],
        // }
        // state.processedDataMeta = {
        //     total: 3,
        //     count: 3,
        //     per_page: 15,
        //     current_page: 1,
        //     total_pages: 1,
        // }
      })
  },
})

export const { resetImporterSlice, resetImportData } = importerSlice.actions
export const { reducer } = importerSlice
export const selectImporters = (state: AppState) => state.importer
