import { GlobalStyles, Icon } from '@andromeda'
import { Button, Stack } from '@mui/material'
import { ModalHeader } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useEffect, useState } from 'react'
import { InviteUserForm } from 'src/components/user/invite'
import { trackEvent } from '@utils/tracking/helpers'
import { UsersList } from './components/users-list'
import { Types } from '@orbit'
import AddUsersModalContext, { AddUsersModalContentProps } from './AddUsersModal.context'

const AddUsersModalContent = (props: AddUsersModalContentProps) => {
  const { modalContext, handleInvite } = props
  const { workspace } = useAppSelector(selectWorkspace)
  const [modalProps, setModalProps] = useState(props)
  const [modalTitle, setModalTitle] = useState('Add Users')
  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false)
  const [draftSelection, setDraftSelection] = useState<Types.Job.iJobUsersResponse>({ users: [], invites: [] })

  const { eventTypes, inviteAdditionalInfo } = modalProps

  useEffect(() => {
    setModalProps(props)
  }, [props])

  return (
    <AddUsersModalContext.Provider
      value={{
        modalProps,
        workspace,
        draftSelection,
        isCreatingNewUser,
        setDraftSelection,
        setIsCreatingNewUser,
      }}>
      <Stack
        direction="column"
        height={'auto'}
        className="dialog-container"
        sx={{ backgroundColor: GlobalStyles.MONO_WHITE, width: { sm: '450px' } }}>
        <ModalHeader
          modalContext={modalContext}
          leftIcon={<Icon.UserPlus size={20} color={GlobalStyles.SLATE_300} />}
          modalTitle={modalTitle}
          backButton={
            isCreatingNewUser ? (
              <Button
                variant="outlined"
                style={{ height: '40px' }}
                onClick={() => {
                  setIsCreatingNewUser(false)
                  setModalTitle('Add Users')
                }}>
                <Icon.ArrowLeft size={16} />
              </Button>
            ) : undefined
          }
          noCloseButton={isCreatingNewUser}
        />
        {isCreatingNewUser ? (
          <Stack height="700px">
            <InviteUserForm
              submitOverrideAction={(form) => {
                eventTypes.inviteConfirmed && trackEvent({ event: eventTypes.inviteConfirmed, eventProperties: { workspace } })
                handleInvite(draftSelection, form, (_data) => {
                  setIsCreatingNewUser(false)
                })
              }}
              submitButtonText="Create New User and Continue"
              miniLabels
              showRoleSelection={modalProps.showInviteRoleSelection ?? true}
              additionalInfo={inviteAdditionalInfo}
              hasOwnerShipType
            />
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="flex-start" rowGap="16px" flex={1} style={{ overflowY: 'auto' }}>
            <UsersList
              onClickCreateNew={() => {
                setIsCreatingNewUser(true)
                setModalTitle('Create New User')
              }}
            />
          </Stack>
        )}
      </Stack>
    </AddUsersModalContext.Provider>
  )
}

export default AddUsersModalContent
