import { Button, Popover } from '@mui/material'
import { Types } from '@orbit'
import { useEffect, useRef, useState } from 'react'
import { NativeScrollEvent, ScrollView } from 'react-native'
import { Badge } from '../Badge'
import { Icon } from '../Icon'
import { Loading } from '../Loading'
import { StackV2 } from '../StackV2'
import { Text } from '../Text'
import { View } from '../View'
import { GlobalStyles } from '../globalStyles'
import { default as NotificationFeed } from './NotificationFeed'
import { NotificationStyles } from './styles'
import { iNotificationProps } from './types'
import { markReadAllUserNotification } from '@redux/reducers/notificationsReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { selectAuth } from '@redux/reducers/authReducer'
import { useUnreadNotificationCount } from '@utils/query/useUnreadNotificationCount'
import { Notifications } from '../../components/Notifications'

const Notification = ({
  notifications,
  showBadge = true,
  containerPosition = 'left',
  showHeader = true,
  showScrollBar = false,
  numberOfVisibleFeed = 5,
  ...props
}: iNotificationProps) => {
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)
  const { me } = useAppSelector(selectAuth)
  // const notificationRef = useRef<NativeTouchableOpacity>(null)
  // const [visible, setVisible] = useState<boolean>(false)
  // const [menuPosition, setMenuPosition] = useState<MenuPosition>({ top: 0 })
  // const [notificationYPos, setNotificationYPos] = useState<number>(0)
  // const [notificationHeight, setNotificationHeight] = useState<number>(0)
  // const [notificationContainerWidth, setNotificationContainerWidth] = useState<string | number>(250)
  const [showScroll, setShowScroll] = useState<boolean>(false)
  const feedContainerRef = useRef<any>(null)
  const feedFooterRef = useRef<any>(null)
  const [shouldResize, setShouldResize] = useState<boolean>(true)
  // const [maxContainerHeight, setMaxContainerHeight] = useState<number | string>('auto')

  const [anchorEl, setAnchorEl] = useState<(EventTarget & SVGElement) | null>(null)

  const { data: unreadCount } = useUnreadNotificationCount()

  const _renderHeader = () => {
    if (props.headerElement) return props.headerElement
    return (
      <StackV2 direction="row" columnGap="5px" alignItems="center" padding="16px" borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}>
        <View>
          <Icon.Bell size={GlobalStyles.ICON_SIZE} color={GlobalStyles.SLATE_700} />
        </View>
        <Text size={'base'} bold>
          {'Notifications'}
        </Text>
      </StackV2>
    )
  }

  const _notificationPress = (notif: Types.Notification.iNotification) => {
    props.onNotificationPress && props.onNotificationPress(notif)
    // setVisible(false)
  }

  const _shouldLoad = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) => {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height
  }

  const _renderLoading = () => {
    if (props.loading) {
      if (props.loadingElement) return props.loadingElement
      return (
        <View style={{ padding: GlobalStyles.PADDING_SIZES.sm }}>
          <Loading size={'small'} />
        </View>
      )
    }
  }

  useEffect(() => {
    if (notifications && notifications.length && shouldResize) {
      let newContainerHeight = 0
      const ceiling = notifications.length >= numberOfVisibleFeed && numberOfVisibleFeed ? numberOfVisibleFeed : notifications.length
      for (let i = 0; i < ceiling; i++) {
        newContainerHeight += feedContainerRef?.current?.children[i]?.scrollHeight || 200
      }
      const footerElementHeight = feedFooterRef?.current?.children[0]?.scrollHeight || 0
      newContainerHeight += footerElementHeight

      // setMaxContainerHeight(newContainerHeight + 60)
      setShouldResize(false)
    }
  }, [notifications])
  const id = Boolean(anchorEl) ? 'notification-popover' : undefined

  const onClickMarkAllRead = () => {
    trackEvent({
      event: TrackingEventEnums.Notification.MARK_ALL_NOTIFICATION_READ,
      eventProperties: {
        workspace: workspace,
        component_name: 'Header Menu',
        role: me?.roles[0],
        unreadCount,
      },
    })

    dispatch(markReadAllUserNotification()).then(() => {
      props.onPress && props.onPress()
    })
  }

  return (
    <View style={[NotificationStyles.mainWrapper]}>
      <Icon.Bell
        size={props.iconSize || GlobalStyles.ICON_SIZE}
        color={props.iconColor || GlobalStyles.SLATE_700}
        onClick={(e) => {
          props.onPress && props.onPress()
          setAnchorEl(e.currentTarget)
        }}
        aria-describedby={id}
      />
      {showBadge && (
        <Badge
          text={props.notificationCount?.toString()}
          textStyle={[NotificationStyles.notificationBadgeText, props.badgeTextStyle]}
          style={[NotificationStyles.notificationBadge, props.badgeStyle]}
        />
      )}

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <StackV2 flex={1} maxHeight={{ xxs: '80vh' }} width={{ sm: '540px' }}>
          {showHeader && _renderHeader()}
          <Notifications onClose={() => setAnchorEl(null)} />
          {_renderLoading()}
        </StackV2>
        <StackV2 alignItems="center">
          <Button
            variant="text"
            startIcon={<Icon.Check />}
            sx={{ padding: 0 }}
            onClick={onClickMarkAllRead}
            disabled={props.notificationCount === 0}>
            Mark All as Read
          </Button>
        </StackV2>
      </Popover>
      {/* <NativeModal visible={visible} animationType="none" transparent>
                <NativeTouchableOpacity style={NotificationStyles.notificationModalSurface} onPress={_modalOnClose}>
                    <NativeTouchableOpacity
                        style={[
                            NotificationStyles.notificationContainer,
                            { ...menuPosition },
                            props.containerStyle,
                            { width: notificationContainerWidth },
                            { maxHeight: maxContainerHeight },
                        ]}
                        activeOpacity={1}
                        onPress={_modalOnClose}
                        onLayout={(e) => {
                            const height = e.nativeEvent.layout.height
                            const willOverlap = windowHeight - (notificationYPos + notificationHeight) < height
                            if (willOverlap) {
                                const newPos: MenuPosition = { bottom: 2 }
                                if (menuPosition.left) newPos.left = menuPosition.left
                                if (menuPosition.right) newPos.right = menuPosition.right
                                setMenuPosition(newPos)
                            }
                        }}>
                        <Stack flex={1} direction={'column'} height={'auto'} space={'none'}>
                            {showHeader && _renderHeader()}
                            {_renderNotifications()}
                            {_renderLoading()}
                            <View ref={feedFooterRef}>{props.footerElement && props.footerElement}</View>
                        </Stack>
                    </NativeTouchableOpacity>
                </NativeTouchableOpacity>
            </NativeModal> */}
    </View>
  )
}

export default Notification
