import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { API } from '../types'

export const AxiosAuthInstance = axios.create({
  baseURL: process.env.oAuthUrl,
})
export const AxiosBaseInstance = axios.create({
  baseURL: process.env.baseUrl,
})

export const HttpClient = {
  get<PayloadType, ReturnType, Metadata = { meta: null }>(
    parameters: API.iHttpClientRequestParameters<PayloadType>
  ): Promise<{ data: ReturnType; meta?: Metadata; status: number }> {
    const { endpoint } = parameters

    const callApi = async function () {
      let response = await AxiosBaseInstance.get(endpoint)
      return { data: response.data.data, meta: response.data.meta, status: response.status }
    }

    return callApi()
  },

  /**
   * Normal PUT Requests
   * @param parameters
   * @returns
   */
  put<PayloadType, ReturnType>(parameters: API.iHttpClientRequestParameters<PayloadType>): Promise<{ data: ReturnType; status: number }> {
    const { endpoint, payload } = parameters

    const body = { ...payload }

    const callApi = async function () {
      const response = await AxiosBaseInstance.put<AxiosResponse<ReturnType>>(endpoint, body)

      return {
        data: response.data.data,
        status: response.status,
      }
    }

    return callApi()
  },

  /**
   * This ones for Normal POST Requests
   * @param parameters
   * @returns
   */
  post<PayloadType, ReturnType>(parameters: API.iHttpClientRequestParameters<PayloadType>): Promise<{ data: ReturnType; status: number }> {
    const { endpoint, payload } = parameters

    let body = { ...payload }

    const callApi = async function () {
      let response = await AxiosBaseInstance.post<AxiosResponse<ReturnType>>(endpoint, body)
      return { data: response.data.data, status: response.status }
    }
    return callApi()
  },
  /**
   * This ones for OAuth Requests because OAuth requests call a separate endpoing and does not have a data object
   * @param parameters
   * @param grant_type
   * @returns
   */
  auth<PayloadType, ReturnType>(
    parameters: API.iHttpClientRequestParameters<PayloadType>,
    grant_type?: API.GrantOptions
  ): Promise<ReturnType & { status: number }> {
    const { endpoint, payload } = parameters

    const options: AxiosRequestConfig = {}

    let auths = {
      // Hard-code these two values to use with yarn link
      // client_id: '97b4f3e9-ecfe-4d9f-8274-0019909a6a1b',
      // client_secret: 'A5pB1Oj6sHNLgo4zT9ZNua7OpLg75xaSEqKlvwZX',
      client_id: process.env.clientId,
      client_secret: process.env.clientSecret,
      grant_type: grant_type ?? API.GrantOptions.PASSWORD,
      scope: '*',
    }

    let body = { ...payload, ...auths }

    const callApi = async function () {
      let response = await AxiosAuthInstance.post(endpoint, body, options)
      AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
      AxiosBaseInstance.defaults.headers.common['Accept'] = `application/json`
      return { ...response.data, status: response.status }
    }
    return callApi()
  },

  delete<PayloadType, ReturnType>(
    parameters: API.iHttpClientRequestParameters<PayloadType>
  ): Promise<{ data: ReturnType; status: number }> {
    const { endpoint } = parameters

    const options: AxiosRequestConfig = {
      headers: {},
    }

    const callApi = async function () {
      let response = await AxiosBaseInstance.delete<AxiosResponse<ReturnType>>(endpoint, options)

      return response.data
    }

    return callApi()
  },
}
