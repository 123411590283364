import { DropdownOption, GlobalStyles, Icon, Loading, Text, View, applyResponsiveProp, useScreenDimensions } from '@andromeda'
import { Button, IconButton, InputBase, PaginationItem, Stack } from '@mui/material'
import MuiPagination from '@mui/material/Pagination'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listJobUsers, resetUserList, selectUsers } from '@redux/reducers/usersReducer'
import { AppStyles } from '@styles'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { trackEvent } from '@utils/tracking/helpers'
import { NoMatchResult } from '@components'
import { MouseEventHandler } from 'react'
import { ModalContext, ModalOptions } from '@utils/context/Modal.context'
import { ModalPages } from '@utils/context/Modal.context'
import { useRouter } from 'next/router'
import { UsersListItem, UsersListItemProps } from './users-list-item'
import { SelectedUsers } from './selected-users'
import { PaginationStyle } from './styles'
import AddUsersModalContext from '../AddUsersModal.context'
import { useMe } from '@utils/query/useMe'

type UserListProps = {
  onClickCreateNew?: MouseEventHandler<HTMLButtonElement>
}

const UsersList = ({ onClickCreateNew }: UserListProps) => {
  const { modalProps, workspace, draftSelection, setDraftSelection } = useContext(AddUsersModalContext)
  const { enableCreateUser, hasUpdatePermissions, eventTypes, redirectRoute, currentSelectedUsers, handleSaveUsers, handleLeave } =
    modalProps
  const dispatch = useAppDispatch()
  const router = useRouter()

  const { size: screenSize } = useScreenDimensions()

  const { closeModal, toggleModal, options } = useContext(ModalContext)
  const { jobUsers: users, listMeta: usersMeta, listStatus: usersListStatus } = useAppSelector(selectUsers)

  const [queryString, setQueryString] = useState<string | undefined>(undefined)
  const [searchKeys, setSearchKeys] = useState<string[]>([])

  const [usersList, setUsersList] = useState<Array<Types.User.iUser> | null>([])
  const [draftList, setDraftList] = useState<Array<Types.User.iUser>>([])
  const [showMembersOnly, setShowMembersOnly] = useState<boolean>(false)

  const usersPaginationData = useMemo(() => {
    if (usersMeta) {
      const {
        pagination: { current_page, total_number_of_pages, total_results },
      } = usersMeta
      const currentPage = current_page || 0
      const totalPage = total_number_of_pages
      const nextPage = currentPage + 1 > totalPage ? 0 : currentPage + 1
      const previousPage = currentPage - 1 <= 0 ? 0 : currentPage - 1
      const totalResult = total_results || 0

      return {
        currentPage,
        totalPage,
        nextPage,
        previousPage,
        totalResult,
      }
    }
  }, [usersMeta])

  const { data: loggedInUser } = useMe()

  const doFetchUsers = useCallback(
    (params: Types.User.iUserListParams) => {
      dispatch(listJobUsers(params))
    },
    [dispatch]
  )

  const doReset = useCallback(() => {
    dispatch(resetUserList())
  }, [dispatch])

  const prepareDraftSelection = useCallback(() => {
    let draftUsers: Types.Job.iDeliverableUsersResponse = { users: [], invites: [] }

    if (currentSelectedUsers) {
      draftUsers = { ...currentSelectedUsers }
      setDraftList([...currentSelectedUsers.users, ...currentSelectedUsers.invites])
    }

    setDraftSelection(draftUsers)
  }, [currentSelectedUsers, setDraftSelection])

  const _goToDirectory = () => {
    closeModal(ModalPages.USERPERMISSION)
    router.isReady && redirectRoute && router.push(redirectRoute)
  }

  const _filterDraftUsers = (newSearchKeys: Array<string>) => {
    if (newSearchKeys.length > 0) {
      const filteredDraft = draftList.filter((draft) => {
        let hit = 0
        newSearchKeys.forEach((key) => {
          if (draft.name.toLowerCase().includes(key.toLowerCase())) hit++
        })
        return hit > 0
      })
      setDraftList(filteredDraft)
    } else if (currentSelectedUsers) {
      setDraftList([...currentSelectedUsers.users])
    }
  }

  const _handleSearch = (e: any) => {
    e.nativeEvent.stopImmediatePropagation()

    if (e.nativeEvent.key === 'Enter') {
      const key = e.target.value.trim()
      const params: Types.User.iUserListParams = { page: '1', query: key }

      if (showMembersOnly) {
        _filterDraftUsers(searchKeys)
      } else {
        doFetchUsers(params)
      }
    }
  }

  const _handleUserSelect: UsersListItemProps['onUserSelect'] = (e, _index) => {
    let draftClone: Types.Job.iJobUsersResponse = { users: [], invites: [] }

    if (eventTypes && eventTypes.select && eventTypes.unselect) {
      trackEvent({
        event: e.selected ? eventTypes.select : eventTypes.unselect,
        eventProperties: { workspace },
      })
    }

    if (draftSelection) {
      draftClone = { ...draftSelection }

      if (e.selected) {
        draftClone.users = [...draftClone.users, { ...e.details, ownership_type: 'OWNER' }]
        setDraftSelection(draftClone)
      } else {
        const hasOneRemainingOwner = draftClone?.users?.filter((owner) => owner.ownership_type === 'OWNER').length === 1

        if (hasOneRemainingOwner) {
          const modalOptions: ModalOptions = {
            hideOthers: false,
          }

          e.setIsSelected(true)
          toggleModal(ModalPages.OWNER_REQUIRED, modalOptions)
        } else {
          const isCurrentUser = e.details.eid === loggedInUser?.eid

          if (isCurrentUser) {
            const modalOptions: ModalOptions = {
              hideOthers: false,
              modalProps: {
                ...options?.modalProps,
                [ModalPages.LEAVE_DELIVERABLE]: {
                  onConfirm: () => {
                    handleLeave && handleLeave(loggedInUser, draftSelection, setDraftSelection)
                    closeModal(ModalPages.LEAVE_DELIVERABLE)
                  },
                },
              },
            }

            toggleModal(ModalPages.LEAVE_DELIVERABLE, modalOptions)
            return
          }

          draftClone.users = draftClone?.users?.filter((viewer) => viewer.eid !== e.details.eid)
        }
      }
    }

    setDraftSelection(draftClone)
  }

  const _handlePermissionChange = (e: { user: Types.User.iUser; item: DropdownOption }) => {
    let draftClone = { users: [...draftSelection.users], invites: [...draftSelection.invites] }
    const userIndex = draftClone.users.findIndex((user) => user.eid === e.user.eid)
    const inviteIndex = draftClone.invites.findIndex((user) => user.eid === e.user.eid)

    if (userIndex > -1) {
      draftClone.users[userIndex] = {
        ...draftClone.users[userIndex],
        ownership_type: e.item.eid.toUpperCase(),
      }
    }
    if (inviteIndex > -1) {
      draftClone.invites[inviteIndex] = {
        ...draftClone.invites[inviteIndex],
        ownership_type: e.item.eid.toUpperCase(),
      }
    }

    setDraftSelection(draftClone)
  }

  useEffect(() => {
    if (hasUpdatePermissions) {
      setShowMembersOnly(false)
      doFetchUsers({ page: '1' })
    } else {
      setShowMembersOnly(true)
    }

    return () => {
      doReset()
    }
  }, [doReset, doFetchUsers, hasUpdatePermissions])

  useEffect(() => {
    if (users) {
      setUsersList([
        ...(currentSelectedUsers?.invites && currentSelectedUsers.invites.length ? currentSelectedUsers.invites : []),
        ...users,
      ])
    }
  }, [users, currentSelectedUsers?.invites])

  useEffect(() => {
    if (currentSelectedUsers) {
      prepareDraftSelection()
    }
  }, [currentSelectedUsers, prepareDraftSelection])

  return (
    <>
      <Stack className="dialog-content" direction="column" justifyContent="flex-start" rowGap="24px" flex={1} style={{ overflowY: 'auto' }}>
        <Stack direction={'row'} style={{ backgroundColor: GlobalStyles.SLATE_50, borderRadius: '6px' }} padding="16px 24px">
          <IconButton type="button" size="small" style={{ padding: '0', marginRight: '10px', pointerEvents: 'none' }} aria-label="search">
            <Icon.Search size={16} color={GlobalStyles.SLATE_400} />
          </IconButton>
          <InputBase
            style={{ color: GlobalStyles.SLATE_400, flex: 1 }}
            inputProps={{ 'aria-label': 'Search items' }}
            name={'talent-search-string'}
            placeholder={'Search for user'}
            value={queryString || ''}
            onChange={(text) => setQueryString(text.target.value)}
            onKeyPress={_handleSearch}
          />
        </Stack>

        {/* Alert */}
        {!showMembersOnly && (
          <Stack direction="row" columnGap="12px" alignItems="center">
            <Icon.Info size={AppStyles.ICON_SIZES.XS} color={GlobalStyles.SLATE_500} />
            <Text style={{ color: GlobalStyles.SLATE_500 }} size={'tiny'}>
              Adding users will allow them to receive updates on the job
            </Text>
          </Stack>
        )}

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text size={'sm'}>
            <b>{`${usersPaginationData?.totalResult || 0} Users`}</b>&nbsp;match your criteria
          </Text>
          {enableCreateUser && (
            <Button
              variant="outlined"
              startIcon={<Icon.PlusCircle size={16} />}
              onClick={(event) => {
                eventTypes?.invite && trackEvent({ event: eventTypes.invite, eventProperties: { workspace } })
                onClickCreateNew && onClickCreateNew(event)
              }}>
              Create New User
            </Button>
          )}
        </Stack>

        {usersListStatus === 'pending' ? (
          <View style={{ justifyContent: 'center' }}>
            <Loading />
          </View>
        ) : (usersList && usersList?.length > 0) || (draftSelection && draftSelection.users?.length > 0) ? (
          <>
            {!showMembersOnly && !!usersList?.length && (
              <Stack direction={'column'} maxHeight={450} flex={1} spacing={2}>
                <Stack flex={1} key={draftSelection?.users?.length} direction={'column'} spacing={2}>
                  {usersList?.map((user, index) => {
                    return (
                      <UsersListItem
                        key={user.eid}
                        user={user}
                        itemIndex={index}
                        requestIsCompleted={false}
                        draftSelection={draftSelection}
                        onPermissionSelect={_handlePermissionChange}
                        onUserSelect={_handleUserSelect}
                      />
                    )
                  })}
                </Stack>
              </Stack>
            )}

            {showMembersOnly && draftSelection?.users?.length > 0 && (
              <Stack
                flex={1}
                direction={'column'}
                style={{
                  paddingLeft: AppStyles.PADDING_SIZES.BASE,
                  paddingRight: AppStyles.PADDING_SIZES.BASE,
                  ...applyResponsiveProp(
                    {
                      lg: {
                        paddingLeft: AppStyles.PADDING_SIZES.XL,
                        paddingRight: AppStyles.PADDING_SIZES.XL,
                      },
                      xl: {
                        paddingLeft: AppStyles.PADDING_SIZES.XL,
                        paddingRight: AppStyles.PADDING_SIZES.XL,
                      },
                      xxl: {
                        paddingLeft: AppStyles.PADDING_SIZES.XL,
                        paddingRight: AppStyles.PADDING_SIZES.XL,
                      },
                      default: null,
                    },
                    screenSize
                  ),
                }}>
                {draftList.map((user, index) => {
                  return (
                    <UsersListItem
                      key={user.eid}
                      user={user}
                      itemIndex={index}
                      requestIsCompleted={false}
                      draftSelection={draftSelection}
                      onPermissionSelect={_handlePermissionChange}
                      onUserSelect={_handleUserSelect}
                    />
                  )
                })}
              </Stack>
            )}
          </>
        ) : (
          <View
            style={{
              width: '100%',
              paddingHorizontal: 0,
              marginBottom: AppStyles.MARGIN_SIZES.MD,
            }}>
            <NoMatchResult onPress={_goToDirectory} />
          </View>
        )}
      </Stack>

      {usersList && usersList.length > 0 && !showMembersOnly && (
        <Stack alignItems="center" width="100%" paddingBottom="8px" position="relative">
          <MuiPagination
            page={usersPaginationData?.currentPage || 0}
            size="large"
            siblingCount={0}
            count={usersPaginationData?.totalPage || 0}
            shape="rounded"
            sx={PaginationStyle}
            onChange={(_, page) => {
              const params: Types.User.iUserListParams = { page: page.toString() }
              if (searchKeys.length) params.query = searchKeys
              doFetchUsers(params)
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: () => <Icon.ArrowLeft size={16} />, next: () => <Icon.ArrowRight size={16} /> }}
                {...item}
              />
            )}
          />
        </Stack>
      )}

      <SelectedUsers
        draftSelection={draftSelection}
        hasUpdatePermissions={true}
        onConfirmSelection={() => handleSaveUsers && handleSaveUsers(draftSelection)}
      />
    </>
  )
}

export { UsersList }
