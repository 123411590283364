import { ButtonV2, GlobalStyles, Icon, Loading, Notification, StackV2, Tooltip } from '@andromeda'
import { DrawerContext } from '@components'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { logout, selectAuth } from '@redux/reducers/authReducer'
import {
  listUserNotifications,
  markReadUserNotification,
  markSeenAllUserNotification,
  selectNotifications,
} from '@redux/reducers/notificationsReducer'
import { AppStyles } from '@styles'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { JobPermission, WorkspacePermission } from '@utils/enum/permissions'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { isValidElement, useContext, useState } from 'react'
import { InnerDrawerMenuItem } from '../drawer/MenuItem'
import { PermissionWrapper } from '../hocs'
import { Stack } from '@mui/material'
import { useGlobalSearch } from '@utils/hooks/useGlobalSearch'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { useUnreadNotificationCount } from '@utils/query/useUnreadNotificationCount'

type HeaderMainProps = {
  description: string
  headerButtons?: Array<JSX.Element>
}

const Header = ({ description, headerButtons = [] }: HeaderMainProps) => {
  const router = useRouter()

  const { open, toggleWorkspaceDrawer: toggleOpen } = useContext(DrawerContext)
  const { me: profile, loggedOut, permissions } = useAppSelector(selectAuth)
  const { workspace, pathEnums } = useContext(NavigationContext)
  const { toggleModal } = useContext(ModalContext)
  const { features } = useContext(FeatureFlagsContext)

  const dispatch = useAppDispatch()
  const { listStatus, unreadCount, notificationMeta } = useAppSelector(selectNotifications)
  const [notifications, setNotifications] = useState<Types.Notification.iNotification[]>([])

  const { renderSearchPopover } = useGlobalSearch('popup')
  const unreadNotificationCountQuery = useUnreadNotificationCount()

  const _getNotifications = () => {
    trackEvent({
      event: TrackingEventEnums.Notification.CLICKED_NOTIFICATION_BELL,
      eventProperties: {
        workspace: workspace,
        component_name: 'Header Menu',
        role: profile?.roles[0],
        unreadNotificationCount: unreadNotificationCountQuery.data,
      },
    })
    unreadNotificationCountQuery.refetch()
    setNotifications([])
    dispatch(listUserNotifications({ page: '1' })).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        setNotifications(response.payload.data)
      }
    })
  }

  const _readNotification = (notification: Types.Notification.iNotification) => {
    trackEvent({
      event: TrackingEventEnums.Notification.CLICKED_NOTIFICATION,
      eventProperties: {
        workspace: workspace,
        component_name: 'Header Menu',
        role: profile?.roles[0],
        notification: notification.text,
      },
    })

    if (notification.eid && !notification.read_at) {
      dispatch(markReadUserNotification({ payload: { eids: [notification.eid] } }))
    }
    if (notification.url) {
      router.push(notification.url)
    }
  }

  const _doFetchNextNotifications = () => {
    if (
      listStatus !== 'loading' &&
      notificationMeta?.current_page &&
      notificationMeta?.last_page &&
      notificationMeta?.current_page < notificationMeta?.last_page
    ) {
      dispatch(listUserNotifications({ page: (notificationMeta?.current_page + 1).toString() })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') setNotifications([...notifications, ...response.payload.data])
      })
    }
  }

  return (
    <StackV2
      id="top-links"
      direction={'row'}
      paddingRight={{ xxs: 1, md: GlobalStyles.PADDING_SIZES.xxs }}
      alignItems={'center'}
      paddingTop={'calc(env(safe-area-inset-top))'}
      minHeight={AppStyles.MENU_ITEM_HEIGHT}
      style={{
        justifyContent: 'flex-start',
      }}>
      {!open && (
        <Box display={{ xxs: 'flex', sm: 'none' }}>
          <IconButton
            sx={{
              height: AppStyles.MENU_ITEM_HEIGHT,
              width: AppStyles.MENU_ITEM_HEIGHT,
              padding: 0,
              backgroundColor: { sm: GlobalStyles.MONO_BLACK },
              borderRadius: 0,
            }}
            onClick={() => toggleOpen && toggleOpen()}>
            {<Icon.Menu color="white" />}
          </IconButton>
        </Box>
      )}
      <StackV2 direction={'row'} flex={1} alignItems={'center'} spacing={2} justifyContent={{ xxs: 'space-between', sm: 'center' }}>
        {features.isGlobalSearchEnabled && (
          <Stack flex={{ sm: 1 }} padding={{ xxs: '12px', sm: 0 }} marginLeft={{ xxs: '16px', sm: 0 }}>
            {renderSearchPopover()}
          </Stack>
        )}
        <StackV2 flex={{ sm: 1 }} direction="row" justifyContent="flex-end" alignItems={'center'} spacing={2}>
          {[...headerButtons]?.map((button, index) => (isValidElement(button) ? button : null))}
          {/* <SearchInput /> */}
          {/* <StackV2 display={{ xxs: 'none', sm: 'flex' }} direction={'row'} alignItems={'center'}>
            {!loggedOut && profile ? (
              <Typography
                display={{ xxs: 'none', md: 'flex' }}
                style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.SLATE_100 }}>
                You are logged in as {profile?.name}.
              </Typography>
            ) : (
              <Loading size={'small'} animating={true} type={'blue'} style={{ margin: '0 auto' }} />
            )}
            <ButtonV2
              onClick={() => {
                trackEvent({
                  event: TrackingEventEnums.Account.ACCOUNT_LOGOUT,
                  eventProperties: {
                    workspace: workspace,
                    component_name: 'Header Menu',
                  },
                })
                dispatch(logout())
              }}
              variant="text"
              color={'slate_400'}
              style={{ textDecorationLine: 'underline' }}>
              Log out
            </ButtonV2>
          </StackV2> */}
          <PermissionWrapper permissions={permissions} allowedPermissions={[JobPermission.CREATE]}>
            <Box display={{ xxs: 'none', md: 'flex' }}>
              <ButtonV2
                onClick={() => {
                  trackEvent({
                    event: TrackingEventEnums.Job.JOB_GLOBAL_QUICK_START,
                    eventProperties: {
                      workspace: workspace,
                      component_name: 'Clicked Global Add Job Button',
                    },
                  })
                  toggleModal(ModalPages.ADD_JOB)
                }}
                variant={'contained'}
                startIcon={<Icon.PlusCircle size={AppStyles.ICON_SIZES.SM} />}>
                Add Job
              </ButtonV2>
            </Box>
          </PermissionWrapper>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xxs: 'none', sm: 'initial' }, margin: '8px 24px', backgroundColor: GlobalStyles.SLATE_600 }}
          />
          <Tooltip text="Notifications" showIcon={false}>
            <IconButton sx={{ padding: 0 }}>
              <Notification
                key={'notification-bell'}
                notifications={notifications}
                containerPosition={'right'}
                notificationCount={unreadNotificationCountQuery.data}
                iconColor={GlobalStyles.MONO_WHITE}
                iconSize={GlobalStyles.FONT_SIZES.LARGE}
                loading={listStatus === 'loading'}
                onPress={_getNotifications}
                hover={true}
                onNotificationPress={_readNotification}
                lazyLoad={_doFetchNextNotifications}
                showBadge={unreadNotificationCountQuery.data > 0 ? true : false}
                messageNumberOfLines={2}
              />
            </IconButton>
          </Tooltip>
          <Link href={{ pathname: pathEnums.SETTINGS, query: { workspace } }}>
            <InnerDrawerMenuItem
              title="Settings"
              type={'default'}
              menuItemStyle={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
              selected={router.asPath === pathEnums.SETTINGS}
              icon={<Icon.Settings color={GlobalStyles.MONO_WHITE} size={20} />}
            />
          </Link>
        </StackV2>
      </StackV2>
    </StackV2>
  )
}

export { Header }
