import { Orbit, Types } from '@orbit'
import { Draft, PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { JOB_STATUS } from '@utils/enum/status/job'
import { ApiResponseError } from '@utils/types/auth'
import { JobProps, StatusTypes, iJobDetailsErrorMessages, iJobExternal, iJobInitialState, iJobTab } from '@utils/types/jobs'
import { toast } from 'react-toastify'
import { capitalize, capitalizeFirstLetter, uploadFileToUrl } from '../../utils/functions/helperFunctions'
import { AppState } from '../store'
import { detachAgreementAttachment, uploadAgreementAttachment } from './agreementsReducer'
import { detachDeliverableAttachment, uploadDeliverableAttachment } from './deliverableReducer'

enum JobStatus {
  CREATE_JOB_STATUS = 'createJobStatus',
  DELETE_JOB_STATUS = 'deleteJobStatus',
  VIEWED_JOB_STATUS = 'viewedJobStatus',
  STATUS = 'status',
  UPDATE_JOB_STATUS = 'updateJobStatus',
}

const initialJobDetails: Types.Job.iJob = {
  eid: '',
  name: '',
  description: null,
  client: '',
  department: null,
  organisation: null,
  start_datetime: null,
  end_datetime: null,
  job_budget: 0,
  cost_mode: 'PER_JOB',
  job_cost: null,
  job_currency_code: 'AUD',
  event_type_eids: [],
  number_of_talents_required: null,
  location: {
    address_1: '',
    address_2: null,
    address_3: null,
    city: '',
    country: '',
    state: '',
    postcode: '',
    latitude: null,
    longitude: null,
  },
  created_at: null,
  updated_at: null,
  deleted_at: null,
  number_of_talents_booked: null,
  status: 'DRAFT',
  total_fee: {
    fee_amount: 0,
    fee_currency_code: 'AUD',
  },
  shortlisted_talents: [],
  budget_type: null,
  date_type: null,
  deliverables: [],
}

export const initialJobRequestDetails: iJobExternal = {
  ...initialJobDetails,
  client: null,
  end_datetime: null,
  number_of_talents_required: null,
  start_datetime: null,
  department: null,
  external_client: {
    // @ts-expect-error
    name: null,
    organisation: null,
    phone_number: '',
    email: '',
  },
  client_name: '',
  client_email: '',
  client_phone_number: '',
  client_organization: '',
  location: undefined,
}

const initialTabs: Array<iJobTab> = [
  {
    key: 'ALL',
    label: 'All',
    count: 0,
  },
  /**
   * Commented out as we are not using this tab for now.
    {
      key: 'LEAD',
      label: 'Lead',
      count: 0,
    },
  */
  {
    key: JOB_STATUS.DRAFT,
    label: capitalizeFirstLetter(JOB_STATUS.DRAFT),
    count: 0,
  },
  {
    key: JOB_STATUS.LIVE,
    label: capitalizeFirstLetter(JOB_STATUS.LIVE),
    count: 0,
  },
  {
    key: JOB_STATUS.COMPLETED,
    label: capitalizeFirstLetter(JOB_STATUS.COMPLETED),
    count: 0,
  },
  {
    key: JOB_STATUS.ARCHIVED,
    label: capitalizeFirstLetter(JOB_STATUS.ARCHIVED),
    count: 0,
  },
]

const initialState: iJobInitialState = {
  status: 'idle',
  allJobs: undefined,
  latestJobs: undefined,
  filteredJobs: undefined,
  jobsListMeta: null,
  viewedJob: initialJobDetails,
  viewedJobUsers: null,
  viewedJobStatus: 'idle',
  viewedDeliverableUsersStatus: 'idle',
  updateJobStatus: 'idle',
  deleteJobStatus: 'idle',
  createJobStatus: 'idle',
  attachJobFileStatus: 'idle',
  events: null,
  createJobPayload: initialJobDetails,
  aggregateBucket: [],
  error: null,
  assignTalentStatus: 'idle',
  errorMessages: null,
  completeJobStatus: 'idle',
  createJobRequestPayload: initialJobRequestDetails,
  createJobRequestStatus: 'idle',
  getJobRequestStatus: 'idle',
  shortlistStatus: 'idle',
  publishJobStatus: 'idle',
  tabs: initialTabs,
  viewedDeliverableUsers: null,
  syncDeliverableUsersStatus: 'idle',
  viewedDeliverableStatus: 'idle',
}

export interface iModifiedJobResponse extends Types.Job.iJobsResponse {
  queryParams?: Array<string>
}

export const fetchAllJobs = createAsyncThunk<
  // Return type of the payload creator
  Draft<iModifiedJobResponse>,
  // First argument to the payload creator
  Types.Job.iJobListQueryParams
>('jobs/fetchAllJobs', async (queryParams): Promise<iModifiedJobResponse> => {
  const response = await Orbit.Services.jobService.fetchAllJobs({ per_page: '20', ...queryParams })

  return {
    data: response.data,
    meta: response.meta as Types.Job.iJobListMeta,
    status: response.status,
    queryParams: queryParams.statuses,
  }
})

export const fetchAddedJobs = createAsyncThunk<
  // Return type of the payload creator
  Draft<iModifiedJobResponse>,
  // First argument to the payload creator
  Types.Job.iJobListQueryParams
>('jobs/added', async (queryParams): Promise<iModifiedJobResponse> => {
  const response = await Orbit.Services.jobService.fetchAddedJobs({ per_page: '20', ...queryParams })

  return {
    data: response.data,
    meta: response.meta as Types.Job.iJobListMeta,
    status: response.status,
    queryParams: queryParams.statuses,
  }
})

export const fetchOwnedJobs = createAsyncThunk<
  // Return type of the payload creator
  Draft<iModifiedJobResponse>,
  // First argument to the payload creator
  Types.Job.iJobListQueryParams
>('jobs/owned', async (queryParams): Promise<iModifiedJobResponse> => {
  const response = await Orbit.Services.jobService.fetchOwnedJobs({ per_page: '20', ...queryParams })

  return {
    data: response.data,
    meta: response.meta as Types.Job.iJobListMeta,
    status: response.status,
    queryParams: queryParams.statuses,
  }
})

export const fetchManagedJobs = createAsyncThunk<
  // Return type of the payload creator
  Draft<iModifiedJobResponse>,
  // First argument to the payload creator
  Types.Job.iJobListQueryParams
>('jobs/managed', async (queryParams): Promise<iModifiedJobResponse> => {
  const response = await Orbit.Services.jobService.fetchManagedJobs({ per_page: '20', ...queryParams })

  return {
    data: response.data,
    meta: response.meta as Types.Job.iJobListMeta,
    status: response.status,
    queryParams: queryParams.statuses,
  }
})

export const fetchJobById = createAsyncThunk(
  'jobs/fetchJobById',
  async (payload: { jobEid: string }, { rejectWithValue }): Promise<Types.Job.iJob | any> => {
    try {
      const response = await Orbit.Services.jobService.fetchJobById({ jobEid: payload.jobEid })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const fetchJobUsers = createAsyncThunk<Draft<Types.Job.iJobUsersResponse>, { jobEid: string }>(
  'jobs/fetchJobUsers',
  async ({ jobEid }): Promise<Types.Job.iJobUsersResponse> => {
    const response = await Orbit.Services.jobService.fetchJobUsers({ jobEid })

    return response.data
  }
)

export const fetchDeliverableUsers = createAsyncThunk<Draft<Types.Job.iDeliverableUsersResponse>, { deliverableEid: string }>(
  'jobs/fetchDeliverableUsers',
  async ({ deliverableEid }): Promise<Types.Job.iJobUsersResponse> => {
    const response = await Orbit.Services.jobService.fetchDeliverableUsers({ deliverableEid })

    return response.data
  }
)

export const updateJobById = createAsyncThunk(
  'jobs/updateJobById',
  async (payload: { jobEid: string; payload: Types.Job.iJobPayload }, { rejectWithValue }): Promise<Types.Job.iJob | any> => {
    try {
      const response = await Orbit.Services.jobService.updateJobById(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const deleteJobById = createAsyncThunk<Draft<Types.Job.iJob>, { jobEid: string }>(
  'jobs/deleteJobById',
  async ({ jobEid }): Promise<any> => {
    const response = await Orbit.Services.jobService.deleteJobById({ jobEid })

    return response.status
  }
)

export const archiveJobById = createAsyncThunk<Draft<Types.Job.iJob>, { jobEid: string }>(
  'jobs/archiveJobById',
  async ({ jobEid }): Promise<any> => {
    const response = await Orbit.Services.jobService.archiveJobById({ jobEid })

    return response.status
  }
)

export const createJob = createAsyncThunk(
  'jobs/createJob',
  async (payload: Types.Job.iJobPayload, { rejectWithValue }): Promise<Types.Job.iJobPayload | any> => {
    try {
      const response = await Orbit.Services.jobService.create({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const createJobPlus = createAsyncThunk(
  'jobs/createJobPlus',
  async (payload: Types.Job.iJobPlusPayload, { rejectWithValue }): Promise<Types.Job.iJobPayload | any> => {
    try {
      const response = await Orbit.Services.jobService.createPlus({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const getEventTypes = createAsyncThunk<Draft<Types.Events.iEventTypes[]>, undefined>(
  'jobs/events',
  async (_): Promise<Types.Events.iEventTypes[]> => {
    const response = await Orbit.Services.jobService.fetchJobEventTypes()
    return response.data
  }
)

export const createJobShortlist = createAsyncThunk(
  'job/createTalentShortlist',
  async (
    payload: { jobEid: string; payload: Types.Talent.iJobTalentShortlistPayload },
    { rejectWithValue }
  ): Promise<Types.Job.iJob | any> => {
    try {
      const response = await Orbit.Services.jobService.jobShortlistCreate(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateJobShortlist = createAsyncThunk(
  'job/updateTalentShortlist',
  async (
    payload: { jobEid: string; payload: Types.Talent.iJobTalentShortlistPayload },
    { rejectWithValue }
  ): Promise<Types.Job.iJob | any> => {
    try {
      const response = await Orbit.Services.jobService.jobShortlistUpdate(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const publishJob = createAsyncThunk('job/publish', async (jobEid: string, { rejectWithValue }): Promise<Types.Job.iJob | any> => {
  try {
    const response = await Orbit.Services.jobService.publishJob({ jobEid })
    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const assignTalents = createAsyncThunk(
  'job/assignTalents',
  async (payload: Types.Job.iAssignTalentToJob, { rejectWithValue }): Promise<Types.Agreements.iJobAgreementList | any> => {
    try {
      const response = await Orbit.Services.jobService.assignTalent({ payload })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const syncJobUsers = createAsyncThunk(
  'job/syncJobUsers',
  async (payload: { eid: string; payload: Types.Job.iJobSyncPayload }, { rejectWithValue }): Promise<Types.Job.iJobUsersResponse | any> => {
    try {
      const response = await Orbit.Services.jobService.syncJobUsers({ jobEid: payload.eid, payload: payload.payload })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const syncDeliverableUsers = createAsyncThunk(
  'job/syncDeliverableUsers',
  async (
    payload: { eid: string; payload: Types.Job.iDeliverableSyncPayload },
    { rejectWithValue }
  ): Promise<Types.Job.iJobUsersResponse | any> => {
    try {
      const response = await Orbit.Services.jobService.syncDeliverableUsers({ deliverableEid: payload.eid, payload: payload.payload })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const completeJob = createAsyncThunk('job/complete', async (jobEid: string, { rejectWithValue }): Promise<Types.Job.iJob | any> => {
  try {
    const response = await Orbit.Services.jobService.completeJob({ jobEid })
    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const createJobRequest = createAsyncThunk(
  'jobs/createJobRequest',
  async (payload: Types.Job.iJobExternal, { rejectWithValue }): Promise<Types.Job.iJobExternal | any> => {
    try {
      const response = await Orbit.Services.jobService.createJobRequest({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const fetchJobRequestById = createAsyncThunk(
  'jobs/fetchJobRequestById',
  async (payload: { jobRequestEid: string; token: string }, { rejectWithValue }): Promise<Types.Job.iJobExternal | any> => {
    try {
      const response = await Orbit.Services.jobService.fetchJobRequestById(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const acceptJobRequest = createAsyncThunk(
  'jobs/acceptJobRequest',
  async (
    payload: { jobRequestEid: string; token: string; payload: Types.Job.iJobExternal },
    { rejectWithValue }
  ): Promise<Types.Job.iJobExternal | any> => {
    try {
      const response = await Orbit.Services.jobService.acceptJobRequestById(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const leaveJob = createAsyncThunk('job/leave', async (payload: { eid: string }, { rejectWithValue }): Promise<any> => {
  try {
    const response = await Orbit.Services.jobService.leaveJob({ jobEid: payload.eid })
    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const getJobAggregates = createAsyncThunk(
  'jobs/aggregates',
  async (payload: { jobType: 'all' | 'added' | 'owned' | 'managed' }, { rejectWithValue }): Promise<iModifiedJobResponse | any> => {
    try {
      switch (payload.jobType) {
        case 'all':
          const allJobsAggregates = await Orbit.Services.jobService.fetchAllJobs({
            page: '1',
            per_page: '20',
            show_aggregations_only: true,
          })
          return allJobsAggregates
        case 'added':
          const addedJobAggregates = await Orbit.Services.jobService.fetchAddedJobs({
            page: '1',
            per_page: '20',
            show_aggregations_only: true,
          })
          return addedJobAggregates
        case 'owned':
          const ownedJobAggregates = await Orbit.Services.jobService.fetchOwnedJobs({
            page: '1',
            per_page: '20',
            show_aggregations_only: true,
          })
          return ownedJobAggregates
        case 'managed':
          const managedJobAggregates = await Orbit.Services.jobService.fetchManagedJobs({
            page: '1',
            per_page: '20',
            show_aggregations_only: true,
          })
          return managedJobAggregates
        default:
        // return await Orbit.Services.jobService.fetchAllJobs({ per_page: '20', show_aggregations_only: true })
      }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const uploadJobAttachment = createAsyncThunk(
  'jobs/uploadJobAttachment',
  async ({ eid, files }: { eid: string; files: File[] }, { rejectWithValue }): Promise<any> => {
    try {
      const file_names = files.map(({ name }) => name)
      const generateURLResponse = await Orbit.Services.jobService.generateFileUploadUrl({ eid, file_names })

      await Promise.all(
        files.map(async (file, index) => {
          const res = await uploadFileToUrl(file, generateURLResponse.data[index].upload_presign_url)
        })
      )

      const response = await Orbit.Services.jobService.attachFiles({
        eid,
        payload: { files: generateURLResponse.data.map(({ eid }) => eid) },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const detachJobAttachment = createAsyncThunk(
  'jobs/detachJobAttachment',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.jobService.detachFiles({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const generateJobFileDownloadUrl = createAsyncThunk(
  'jobs/generateJobFileDownloadUrl',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.jobService.generateFileDownloadUrl({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetJobsSlice: () => initialState,
    resetJobStatus: (state: iJobInitialState, action: PayloadAction<string>) => {
      const keys = Object.keys(state)
      const findTargetStatus = keys.find((key) => (action.payload as JobStatus) === key)

      state[findTargetStatus as JobStatus] = 'idle'

      return state
    },
    setCreateJobPayload: (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
      state.createJobPayload = action.payload
    },
    setDraftJobUserPayload: (state: iJobInitialState, action: PayloadAction<Types.Job.iJobUsersResponse>) => {
      state.viewedJobUsers = action.payload
    },
    resetCreateJob: (state: iJobInitialState) => {
      state.createJobPayload = initialJobDetails
    },
    setUpdateJobPayload: (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
      state.viewedJob = action.payload
    },
    setViewedJobStatus: (state: iJobInitialState, { payload }: PayloadAction<StatusTypes>) => {
      state.viewedJobStatus = payload
    },
    setViewedJob: (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
      state.viewedJob = action.payload
    },
    setViewedJobUsers: (state: iJobInitialState, { payload }: PayloadAction<Types.Job.iJobUsersResponse>) => {
      state.viewedJobUsers = payload
    },
    resetViewedJob: (state: iJobInitialState) => {
      state.viewedJob = initialJobDetails
    },
    resetViewedDeliverableUsers: (state: iJobInitialState) => {
      state.viewedDeliverableUsers = null
    },
    resetViewedJobUsers: (state: iJobInitialState) => {
      state.viewedJobUsers = null
    },
    setViewedJobDeliverables: (state: iJobInitialState, action: PayloadAction<Types.Deliverable.iDeliverable[]>) => {
      state.viewedJob.deliverables = action.payload
    },
    setAllJobs: (state: iJobInitialState, action: PayloadAction<JobProps[]>) => {
      state.allJobs = action.payload
    },
    setErrorMessages: (state: iJobInitialState, action: PayloadAction<iJobDetailsErrorMessages | null>) => {
      state.errorMessages = action.payload
    },
    resetCreateJobRequest: (state: iJobInitialState) => {
      state.createJobRequestPayload = initialJobRequestDetails
      state.errorMessages = null
    },
    setCreateJobRequestPayload: (state: iJobInitialState, action: PayloadAction<iJobExternal>) => {
      state.createJobRequestPayload = action.payload
    },
    updateViewedJobDeliverable: (state: iJobInitialState, action: PayloadAction<Types.Deliverable.iDeliverable>) => {
      const deliverableIndex = state.viewedJob.deliverables?.findIndex(({ eid }) => eid === action.payload.eid) ?? -1
      if (deliverableIndex > -1 && state.viewedJob.deliverables?.length) {
        const { deliverable_agreements, ...payload } = action.payload
        state.viewedJob.deliverables[deliverableIndex] = {
          ...state.viewedJob.deliverables[deliverableIndex],
          ...payload,
          deliverable_agreements: deliverable_agreements ?? state.viewedJob.deliverables[deliverableIndex].deliverable_agreements,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch Job By ID
      .addCase(fetchJobById.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
        state.viewedJobStatus = 'success'
        const parsePayload = action.payload
        if (parsePayload.event_types && parsePayload.event_types.length) {
          const eventTypeEids = parsePayload.event_types.map((eventType) => eventType.eid)
          parsePayload.event_type_eids = eventTypeEids
        }
        state.viewedJob = parsePayload
      })
      .addCase(fetchJobById.pending, (state) => {
        state.viewedJobStatus = 'loading'
        state.viewedJob = initialJobDetails
      })
      .addCase(fetchJobById.rejected, (state, action) => {
        state.viewedJobStatus = 'failed'
      })
      .addCase(fetchJobUsers.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJobUsersResponse>) => {
        state.viewedJobUsers = action.payload
      })
      .addCase(fetchDeliverableUsers.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iDeliverableUsersResponse>) => {
        state.viewedDeliverableUsersStatus = 'success'
        state.viewedDeliverableUsers = action.payload
      })
      .addCase(fetchDeliverableUsers.rejected, (state: iJobInitialState, action) => {
        state.viewedDeliverableUsersStatus = 'failed'
      })
      .addCase(fetchDeliverableUsers.pending, (state: iJobInitialState, action) => {
        state.viewedDeliverableUsersStatus = 'loading'
        state.viewedDeliverableUsers = null
      })
      .addCase(syncJobUsers.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJobUsersResponse>) => {
        state.viewedJobUsers = action.payload
      })
      .addCase(syncDeliverableUsers.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iDeliverableUsersResponse>) => {
        state.syncDeliverableUsersStatus = 'success'
        state.viewedDeliverableUsers = action.payload
      })
      .addCase(syncDeliverableUsers.rejected, (state: iJobInitialState, action) => {
        state.syncDeliverableUsersStatus = 'failed'
      })
      .addCase(syncDeliverableUsers.pending, (state: iJobInitialState, action) => {
        state.syncDeliverableUsersStatus = 'loading'
        state.viewedDeliverableUsers = null
      })
      // Update Job By ID
      .addCase(updateJobById.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
        toast.success('Job has been updated.')
        state.updateJobStatus = 'success'
        const parsePayload = action.payload
        if (parsePayload.event_types && parsePayload.event_types.length) {
          const eventTypeEids = parsePayload.event_types.map((eventType) => eventType.eid)
          parsePayload.event_type_eids = eventTypeEids
        }
        state.viewedJob = { ...parsePayload, deliverables: state.viewedJob.deliverables }
      })
      .addCase(updateJobById.pending, (state) => {
        state.updateJobStatus = 'loading'
      })
      .addCase(updateJobById.rejected, (state, action) => {
        state.errorMessages = (action.payload as { errors: iJobDetailsErrorMessages }).errors
        toast.error((action.payload as { message: string }).message)
        state.updateJobStatus = 'failed'
      })
      .addCase(getEventTypes.fulfilled, (state, action) => {
        state.events = action.payload
      })
      // Job deliverables
      .addCase(createJobShortlist.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
        if (state.viewedJob) {
          state.viewedJob['shortlisted_talents'] = action.payload.shortlisted_talents
        }
      })
      .addCase(updateJobShortlist.fulfilled, (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
        if (state.viewedJob) {
          state.viewedJob['shortlisted_talents'] = action.payload.shortlisted_talents
        }
      })
      .addCase(assignTalents.pending, (state: iJobInitialState) => {
        state.assignTalentStatus = 'pending'
      })
      .addCase(assignTalents.fulfilled, (state: iJobInitialState) => {
        state.assignTalentStatus = 'success'
      })
      .addCase(assignTalents.rejected, (state: iJobInitialState) => {
        state.assignTalentStatus = 'failed'
      })
      .addCase(completeJob.pending, (state: iJobInitialState) => {
        state.completeJobStatus = 'loading'
      })
      .addCase(completeJob.fulfilled, (state: iJobInitialState) => {
        state.completeJobStatus = 'success'
      })
      .addCase(completeJob.rejected, (state: iJobInitialState) => {
        state.completeJobStatus = 'failed'
      })
      .addCase(createJobRequest.fulfilled, (state: iJobInitialState) => {
        state.createJobRequestStatus = 'success'
        state.createJobRequestPayload = initialJobRequestDetails
        state.errorMessages = null
      })
      .addCase(createJobRequest.rejected, (state: iJobInitialState, action) => {
        const errors = (action.payload as { errors: iJobDetailsErrorMessages }).errors
        const jobNameError = (errors && errors['name' as keyof iJobDetailsErrorMessages]) || undefined
        if (jobNameError) {
          errors.name = jobNameError[0].includes('required') ? 'The job title field is required.' : jobNameError
        }
        const clientNameError = (errors && errors['external_client.name' as keyof iJobDetailsErrorMessages]) || undefined
        if (clientNameError) {
          errors.client = clientNameError[0].includes('required') ? 'The name field is required.' : clientNameError
        }
        const clientEmailError = (errors && errors['external_client.email' as keyof iJobDetailsErrorMessages]) || undefined
        if (clientEmailError) {
          errors.email = clientEmailError[0].includes('required') ? 'The email field is required.' : clientEmailError
        }
        const clientPhoneError = (errors && errors['external_client.phone_number' as keyof iJobDetailsErrorMessages]) || undefined
        if (clientPhoneError) {
          errors.phone_number = clientPhoneError[0].includes('required') ? 'The phone number field is required.' : clientPhoneError
        }
        const clientOrganizationError = (errors && errors['external_client.email' as keyof iJobDetailsErrorMessages]) || undefined
        if (clientOrganizationError) {
          errors.organization = clientOrganizationError[0].includes('required')
            ? 'The organization field is required.'
            : clientOrganizationError
        }
        state.errorMessages = errors
      })
      .addCase(createJobRequest.pending, (state: iJobInitialState, action) => {
        state.errorMessages = null
        state.createJobRequestStatus = 'loading'
      })
      .addCase(fetchJobRequestById.fulfilled, (state: iJobInitialState, action: PayloadAction<iJobExternal>) => {
        try {
          state.createJobRequestPayload = action.payload
          state.createJobRequestPayload.client_name = action.payload.external_client.name
          state.createJobRequestPayload.client_email = action.payload.external_client.email
          state.createJobRequestPayload.client_phone_number = action.payload.external_client.phone_number
          // @ts-expect-error
          state.createJobRequestPayload.client_organization = action.payload.external_client.organisation?.eid || ''
        } catch (e) {
          console.error(e)
        }
        state.getJobRequestStatus = 'success'
      })
      .addCase(fetchJobRequestById.pending, (state: iJobInitialState) => {
        state.getJobRequestStatus = 'loading'
      })
      .addCase(fetchJobRequestById.rejected, (state: iJobInitialState) => {
        state.getJobRequestStatus = 'failed'
      })
      .addCase(publishJob.fulfilled, (state: iJobInitialState) => {
        state.publishJobStatus = 'success'
      })
      .addCase(publishJob.pending, (state: iJobInitialState) => {
        state.publishJobStatus = 'loading'
      })
      .addCase(publishJob.rejected, (state: iJobInitialState) => {
        state.publishJobStatus = 'failed'
      })
      .addCase(getJobAggregates.fulfilled, (state: iJobInitialState, action: PayloadAction<iModifiedJobResponse>) => {
        state.tabs = state.tabs.map((t) => {
          if (t.key === 'ALL') {
            return { ...t, count: action.payload.meta.pagination.total_results || 0 }
          } else if (t.key === 'LEAD') {
            return {
              ...t,
              count: action.payload.meta.aggregations.is_lead.buckets.find((bucket) => bucket.key_as_string === 'true')?.doc_count || 0,
            }
          } else {
            return {
              ...t,
              count: action.payload.meta.aggregations.status.buckets.find((bucket) => bucket.key === t.key)?.doc_count || 0,
            }
          }
        })
      })
      // .addCase(
      //     assignTalentToDeliverable.fulfilled,
      //     (state: iJobInitialState, action: PayloadAction<Types.Deliverable.iDeliverable[]>) => {
      //     aa    const udpatedDeliverables = state.viewedJob.deliverables?.map((deliverable) => {
      //             const updatedDeliverableIndex = action.payload?.findIndex((d) => d.eid === deliverable.eid)
      //             if (updatedDeliverableIndex > -1) {
      //                 return {
      //                     ...deliverable,
      //                     deliverable_agreements: action.payload?.[updatedDeliverableIndex].deliverable_agreements,
      //                 }
      //             }
      //             return deliverable
      //         })
      //         state.viewedJob.deliverables = udpatedDeliverables
      //     }
      // )
      .addMatcher(isAnyOf(createJobShortlist.pending, updateJobShortlist.pending), (state: iJobInitialState) => {
        state.shortlistStatus = 'loading'
      })
      .addMatcher(isAnyOf(createJobShortlist.rejected, updateJobShortlist.rejected), (state: iJobInitialState) => {
        state.shortlistStatus = 'failed'
      })
      .addMatcher(isAnyOf(createJobShortlist.fulfilled, updateJobShortlist.fulfilled), (state: iJobInitialState) => {
        state.shortlistStatus = 'success'
      })
      .addMatcher(isAnyOf(deleteJobById.fulfilled, archiveJobById.fulfilled), (state: iJobInitialState) => {
        state.deleteJobStatus = 'success'
      })
      .addMatcher(isAnyOf(deleteJobById.pending, archiveJobById.pending), (state: iJobInitialState) => {
        state.deleteJobStatus = 'loading'
      })
      .addMatcher(isAnyOf(deleteJobById.fulfilled, archiveJobById.fulfilled), (state: iJobInitialState) => {
        state.deleteJobStatus = 'failed'
      })
      .addMatcher(
        isAnyOf(
          uploadJobAttachment.pending,
          uploadDeliverableAttachment.pending,
          uploadAgreementAttachment.pending,
          detachDeliverableAttachment.pending,
          detachJobAttachment.pending,
          detachAgreementAttachment.pending
        ),
        (state: iJobInitialState) => {
          state.attachJobFileStatus = 'loading'
        }
      )
      .addMatcher(
        isAnyOf(
          uploadDeliverableAttachment.rejected,
          uploadJobAttachment.rejected,
          uploadAgreementAttachment.rejected,
          detachDeliverableAttachment.rejected,
          detachJobAttachment.rejected,
          detachAgreementAttachment.rejected
        ),
        (state: iJobInitialState) => {
          state.attachJobFileStatus = 'failed'
        }
      )
      .addMatcher(
        isAnyOf(uploadJobAttachment.fulfilled, detachJobAttachment.fulfilled),
        (state: iJobInitialState, action: PayloadAction<Types.Job.iJob>) => {
          state.attachJobFileStatus = 'success'
          state.viewedJob.files = action.payload.files
        }
      )
      .addMatcher(
        isAnyOf(uploadDeliverableAttachment.fulfilled, detachDeliverableAttachment.fulfilled),
        (state, action: PayloadAction<Types.Deliverable.iDeliverable>) => {
          state.attachJobFileStatus = 'success'
          const deliverableIndex = state.viewedJob.deliverables?.findIndex(({ eid }) => action.payload.eid === eid) ?? -1

          if (deliverableIndex > -1 && state.viewedJob.deliverables?.length) {
            state.viewedJob.deliverables[deliverableIndex].files = action.payload.files
          }
        }
      )
      .addMatcher(
        isAnyOf(uploadAgreementAttachment.fulfilled, detachAgreementAttachment.fulfilled),
        (state, action: PayloadAction<Types.Agreements.iAttachFileResponse & { deliverableEid: string; expandedJobEid?: string }>) => {
          state.attachJobFileStatus = 'success'

          if (state.viewedJob.eid !== '') {
            state.viewedJob.deliverables = state.viewedJob.deliverables?.map((deliverable) => {
              const DAIndex =
                deliverable.deliverable_agreements?.findIndex(
                  (deliverable_agreement) => deliverable_agreement.jobAgreement?.eid === action.payload.eid
                ) ?? -1
              if (DAIndex > -1) {
                // @ts-expect-error
                deliverable.deliverable_agreements[DAIndex].jobAgreement.files = action.payload.files
              }
              return deliverable
            })
          }

          if (action.payload.expandedJobEid) {
            const jobIndex = state.filteredJobs?.findIndex(({ eid }) => eid === action.payload.expandedJobEid) ?? -1
            if (jobIndex > -1 && state.filteredJobs?.[jobIndex].deliverables?.length) {
              state.filteredJobs[jobIndex].deliverables = state.filteredJobs?.[jobIndex].deliverables?.map((deliverable) => {
                const DAIndex =
                  deliverable.deliverable_agreements?.findIndex(
                    (deliverable_agreement) => deliverable_agreement.jobAgreement?.eid === action.payload.eid
                  ) ?? -1
                if (DAIndex > -1) {
                  // @ts-expect-error
                  deliverable.deliverable_agreements[DAIndex].jobAgreement.files = action.payload.files
                }
                return deliverable
              })
            }
          }
        }
      )
      // Create Job
      .addMatcher(isAnyOf(createJob.fulfilled, createJobPlus.fulfilled), (state, action) => {
        state.createJobStatus = 'success'
        state.createJobPayload = initialJobDetails
      })
      .addMatcher(isAnyOf(createJob.pending, createJobPlus.pending), (state) => {
        state.createJobStatus = 'loading'
      })
      .addMatcher(isAnyOf(createJob.rejected, createJobPlus.rejected), (state, action) => {
        state.errorMessages = (action.payload as { errors: iJobDetailsErrorMessages }).errors
        toast.error((action.payload as { message: string }).message)
        state.createJobStatus = 'failed'
      })
      .addMatcher(
        isAnyOf(fetchAllJobs.fulfilled, fetchAddedJobs.fulfilled, fetchOwnedJobs.fulfilled, fetchManagedJobs.fulfilled),
        (state: iJobInitialState, action: PayloadAction<iModifiedJobResponse>) => {
          state.status = 'success'
          const newJobsList = action.payload.data.map((jobData: Types.Job.iJobList) => {
            return {
              ...jobData,
              status: {
                type: jobData.status,
                text: capitalize(jobData.status.toLowerCase()),
              },
            }
          })

          if (
            action.payload.queryParams ||
            (action.payload.meta.aggregations.is_lead.buckets.length > 0 &&
              action.payload.meta.aggregations.is_lead.buckets[0].key_as_string == 'true')
          ) {
            state.filteredJobs = newJobsList
          } else {
            state.allJobs = newJobsList
            if (action.payload.meta.pagination.current_page === 1) {
              state.latestJobs = newJobsList.filter((_, index) => index < 10)
            }
          }

          state.jobsListMeta = action.payload.meta
          state.viewedJob = initialJobDetails
        }
      )
      .addMatcher(isAnyOf(fetchAllJobs.rejected, fetchAddedJobs.rejected, fetchOwnedJobs.rejected, fetchManagedJobs.rejected), (state) => {
        state.status = 'failed'
      })
      .addMatcher(isAnyOf(fetchAllJobs.pending, fetchAddedJobs.pending, fetchOwnedJobs.pending, fetchManagedJobs.pending), (state) => {
        state.status = 'loading'
      })
  },
})

export const {
  resetCreateJob,
  resetCreateJobRequest,
  resetJobStatus,
  resetViewedJob,
  resetViewedJobUsers,
  setAllJobs,
  setCreateJobPayload,
  setCreateJobRequestPayload,
  setErrorMessages,
  setUpdateJobPayload,
  setViewedJobStatus,
  setViewedJob,
  setViewedJobUsers,
  setViewedJobDeliverables,
  resetJobsSlice,
  updateViewedJobDeliverable,
  resetViewedDeliverableUsers,
} = jobsSlice.actions

export const { reducer } = jobsSlice
export const { setDraftJobUserPayload } = jobsSlice.actions

export const selectJobs = (state: AppState) => state.jobs
