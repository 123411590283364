import { useContext, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import BannerMobile from './banner-mobile'
import BannerDesktop from './banner-desktop'
import BannerJobDesktop from './banner-job-destop'
import { WorkspaceThemeContext } from '@utils/context/Theme.context'

const useBannerImage = () => {
  const { primaryColor: backgroundColor, secondaryColor: primary, tertiaryColor: secondary } = useContext(WorkspaceThemeContext)
  const getSvgString = (component: JSX.Element) => {
    return `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(component))}`
  }

  return {
    mobile: getSvgString(<BannerMobile primary={primary} secondary={secondary} backgroundColor={backgroundColor} />),
    desktop: getSvgString(<BannerDesktop primary={primary} secondary={secondary} backgroundColor={backgroundColor} />),
    jobDesktop: getSvgString(<BannerJobDesktop primary={primary} secondary={secondary} backgroundColor={backgroundColor} />),
  }
}

export default useBannerImage
