import { GlobalStyles, Icon, NotificationFeed, Tooltip } from '@andromeda'
import { Skeleton, Stack, Typography } from '@mui/material'
import { Orbit, Types } from '@orbit'
import { InfiniteData, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import moment, { Moment } from 'moment'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const Notifications = ({
  notificationsProp,
  fetchNextPageProp,
  onClose,
}: {
  notificationsProp?: InfiniteData<Types.Notification.iNotification[] | undefined, unknown>
  fetchNextPageProp?: () => void
  onClose?: () => void
}) => {
  const router = useRouter()

  const { data, status, fetchNextPage } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: async ({ pageParam }) => {
      const res = await Orbit.Services.notificationService.listUserNotifications({
        page: pageParam.toString(),
      })
      return res.data as unknown as Types.Notification.iNotification[]
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined
      }
      return lastPageParam + 1
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined
      }
      return firstPageParam - 1
    },
  })

  const notifications = useMemo(() => {
    return notificationsProp ?? data
  }, [data, notificationsProp])

  const { mutate } = useMutation({
    mutationKey: ['markReadUserNotification'],
    mutationFn: async (notificationEid: string) => {
      return await Orbit.Services.notificationService.markReadUserNotification({ payload: { eids: [notificationEid] } })
    },
  })

  const onScrollEnd = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      fetchNextPageProp ? fetchNextPageProp() : fetchNextPage()
    }
  }

  const onClickNotification = (notification: Types.Notification.iNotification) => {
    if (notification.eid && !notification.read_at) {
      mutate(notification.eid)
    }
    if (notification.fe_url) {
      router.push(notification.fe_url)
    }
    onClose && onClose()
  }

  const determineGroup = (value: Moment) => {
    const now = moment().startOf('day')
    if (value.isSame(now, 'day')) {
      return 'today'
    }
    if (value.isAfter(now.clone().subtract(1, 'days').startOf('day'))) {
      return 'Yesterday'
    }
    return 'Older'
  }

  const groupedNotifications = (notifications?.pages.flat() ?? []).reduce<Record<string, Types.Notification.iNotification[]>>(
    (acc, datum) => {
      const group = determineGroup(moment(new Date((datum as unknown as Types.Notification.iNotification).created_at)))
      if (!acc[group]) {
        acc[group] = []
      }
      acc[group].push(datum as unknown as Types.Notification.iNotification)
      return acc
    },
    {}
  )

  if (status === 'error') {
    return (
      <Stack
        className="paper-border"
        alignItems="center"
        justifyContent="center"
        height="180px"
        padding="1rem"
        overflow={'auto'}
        sx={{
          backgroundColor: 'white',
          '>div': {
            borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
          },
        }}>
        <Typography>
          Unable to retrieve notifications. If you haven’t joined a workspace yet, please join one to start receiving updates.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack
      className="paper-border"
      maxHeight="376px"
      height="376px"
      overflow={'auto'}
      sx={{
        backgroundColor: 'white',
        '>div': {
          borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
        },
      }}
      onScroll={onScrollEnd}>
      {notifications?.pages.length && notifications?.pages[0]
        ? groupedNotifications &&
          // @ts-expect-error
          Object.keys(groupedNotifications).reduce((acc, key) => {
            return [
              ...acc,
              <Stack key={key} direction="row" alignItems="center" padding="8px 12px">
                <Typography fontSize="12px" lineHeight="23px" fontWeight={800} textTransform="uppercase" color={GlobalStyles.SLATE_700}>
                  {key}
                </Typography>
              </Stack>,

              ...groupedNotifications[key].map((notification) =>
                notification ? (
                  <NotificationFeed
                    key={notification?.eid}
                    notification={notification}
                    hover={true}
                    onNotificationPress={onClickNotification}
                    messageNumberOfLines={2}
                  />
                ) : (
                  <></>
                )
              ),
            ]
          }, [])
        : new Array(4).fill('').map((_, index) => (
            <Stack key={`skeleton-${index}`} paddingY="12px" borderTop={`1px solid ${GlobalStyles.SLATE_100}`}>
              <Stack key={`skeleton-${index}`} paddingY="17px" paddingRight="12px" columnGap="24px" direction="row" padding="12px">
                <Skeleton variant="rounded" width="100%" height={20} />
              </Stack>
            </Stack>
          ))}
    </Stack>
  )
}
