import { Avatar, AvatarGroup, DropdownOption, GlobalStyles, Icon, RichEditor, RichReader, Tooltip } from '@andromeda'
import { Box, Button, Chip, Grid, Input, Stack, Typography } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { listDepartments, selectRoles } from '@redux/reducers/rolesReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { EditIconProvider } from '@utils/context/EditIcon.context'
import { ModalContext, ModalOptions, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { JobPermission } from '@utils/enum/permissions'
import { JOB_STATUS } from '@utils/enum/status/job'
import { capitalize, formatLocation, getDefaultCurrencyCode } from '@utils/functions/helperFunctions'
import { hasPermission } from '@utils/functions/permission'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { useEscape } from '@utils/hooks/useEscape'
import { useEventTypes } from '@utils/hooks/useEventTypes'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import moment from 'moment'
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useController, useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { STATUS_CHIP_STYLES } from 'src/components/job-card/job-card'
import { JobNotFound } from '../errors'
import { PermissionWrapper } from '../hocs'
import { Loader } from '../loader'
import { ActionMenu } from './ActionMenu'
import { ColumnLabel, CommonGrid, Paper, Row } from './components'
import { useJobActions } from './useJobActions'
import { selectUsers } from '@redux/reducers/usersReducer'
import { useClientOrg } from '@utils/hooks/useClientOrg'
import { FormSearchTagV2, SearchTagV2 } from '../search/searchtag.new'

import { FormAutoCompleteLocation } from 'src/components/autoCompleteLocation'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { QuickLook } from '../QuickLook'
import { Textfield } from '../form/Textfield'
import { FormDateTimePicker } from '../form/DateTimePicker'
import { FormDateRangePicker } from '../form/DateRangePicker'
import { CurrencyTextfield } from '../form/CurrencyTextfield'
import { DevTool } from '@hookform/devtools'
import { FormAutoComplete } from '../form/AutoComplete'
import { COST_MODE_OPTIONS } from '../inline-create/constants'
import { API_DATE_FORMAT } from '@utils/constants/date'
import { useCurrencyFormat } from '@utils/hooks/useCurrencyFormat'
import { Campaign } from '../social-campaigns/job'
import { SocialCampaign } from '../social-campaigns/talent'
import { useLoadOnScroll } from '@utils/hooks/useLoadOnScroll'
import { decodeCustomFieldsValue, getCustomFieldsDefaultValue } from '../custom-fields'
import { useQueryClient } from '@tanstack/react-query'
import useJobUpdate from '@utils/hooks/useJobUpdate'

const GUIDE_TYPE_OPTIONS = [
  { id: 'FIXED', value: 'Fixed' },
  { id: 'FLEXIBLE', value: 'Flexible' },
]

export const isEditingContext = createContext<{ isEditing: boolean; setIsEditing: Dispatch<SetStateAction<boolean>> | (() => void) }>({
  isEditing: false,
  setIsEditing: () => {},
})
export const DrawerContext = createContext<{ isOpen: boolean }>({
  isOpen: false,
})

export const hasUpdatePermissionsContext = createContext<{
  isUpdatable: boolean
  setIsUpdatable: Dispatch<SetStateAction<boolean>> | (() => void)
}>({
  isUpdatable: false,
  setIsUpdatable: () => {},
})

const getDate = (date: string | null) => (date ? date.split(' ')[0] : null)

export type tJobDetailsForm = Types.Job.iJobPayload & { job_currency_code: DropdownOption; plain_description?: string } & Pick<
    Types.Job.iJob,
    'organisation' | 'department'
  >

export const Details = ({ selectedDeliverableEid }: { selectedDeliverableEid?: string }) => {
  const dispatch = useAppDispatch()
  const { viewedJob, viewedJobUsers, viewedJobStatus, events } = useAppSelector(selectJobs)
  const { settings: workspaceSettings, workspace } = useAppSelector(selectWorkspace)
  const { organisations: organizations, departments, departmentslistMeta, listDepartmentsStatus } = useAppSelector(selectRoles)
  const { jobUsers } = useAppSelector(selectUsers)

  const { updateJob, updateJobStatus } = useJobUpdate({ jobEid: viewedJob.eid })

  const { toggleModal } = useContext(ModalContext)
  const { goToRequests } = useContext(NavigationContext)
  const { features } = useContext(FeatureFlagsContext)

  const currenyCodeOptions = useCurrencyCode()
  const formatCurrency = useCurrencyFormat()

  const transformValues = useCallback(
    (viewedJob: Types.Job.iJob) => ({
      department: viewedJob.department ?? null,
      organisation: viewedJob.external_client?.organisation ?? viewedJob.organisation ?? null,
      event_type_eids: viewedJob.event_types?.map((event) => event.eid) ?? [],
      job_currency_code: getDefaultCurrencyCode(viewedJob.job_currency_code, currenyCodeOptions),
      custom_fields: getCustomFieldsDefaultValue('job', workspaceSettings?.custom_field_settings || [], viewedJob.custom_field_data || {}),
    }),
    [currenyCodeOptions, workspaceSettings]
  )
  const { client, ...job } = viewedJob

  // React Hook Form Actions
  const form = useFormContext<tJobDetailsForm>()
  const {
    handleSubmit: doSubmit,
    control,
    reset,
    register,
    formState: { dirtyFields, isDirty, errors },
  } = form

  const {
    field: { value: dateTypeValue, onChange: dateTypeOnChange },
  } = useController({ name: 'date_type', control })
  const {
    field: { value: startDateValue, onChange: startDateOnChange },
  } = useController({ name: 'start_datetime', control })
  const {
    field: { value: endDateValue, onChange: endDateOnChange },
  } = useController({ name: 'end_datetime', control })
  const {
    field: { value: costModeValue, onChange: costModeOnChange },
  } = useController({ name: 'cost_mode', control })
  const {
    field: { value: descriptionValue, onChange: descriptionOnChange },
  } = useController({ name: 'description', control })
  const {
    field: { value: locationValue, onChange: locationChange },
  } = useController({
    name: 'location',
    control,
  })
  const organisationValue = useWatch({ name: 'organisation', control })
  const clientValue = useWatch({ name: 'client', control })
  const eventTypesValue = useWatch({ name: 'event_types', control })

  const canAddUsers = useMemo(() => {
    return hasPermission({
      permissions: viewedJob.permissions,
      allowedPermissions: [JobPermission.ADD_USER],
    })
  }, [viewedJob.permissions])

  const { isEditing, setIsEditing } = useContext(isEditingContext)
  const [isUpdatable, setIsUpdatable] = useState(false)
  const [visibleEditIcon, setVisibleEditIcon] = useState<string | null>(null)
  const [showDetails, setShowDetails] = useState(true)

  const { eventTypeOptions } = useEventTypes({
    selectedEvents: eventTypesValue,
  })
  const { handleSetJobLive } = useJobActions()
  const { orgUserOptions, organisationOptions, organisationOptionsLoads, searchOrgOptions, loadMoreOrgOptions } = useClientOrg({
    // @ts-expect-error
    clientId: clientValue?.id,
    organisation_eid: organisationValue?.eid,
    external_client: viewedJob.external_client,
  })
  const queryClient = useQueryClient()

  const isDraft = useMemo(() => viewedJob.status === JOB_STATUS.DRAFT, [viewedJob.status])

  const departmentsLoadOnScroll = useLoadOnScroll({
    list: departments || [],
    listMeta: departmentslistMeta,
    status: listDepartmentsStatus,
    labelField: 'name',
    fetchList: ({ page, searchTerm }) => dispatch(listDepartments({ page: String(page), query: searchTerm as string })),
  })
  const departmentOptions = useMemo<DropdownOption[]>(() => {
    const _departments = departmentsLoadOnScroll.options || []
    return _departments.length ? _departments.map(({ eid, name }) => ({ eid, name })) : []
  }, [departmentsLoadOnScroll.options])

  useEscape(() => setIsEditing(false), isEditing)

  useEffect(() => {
    const canUpdateJob = hasPermission({
      permissions: viewedJob.permissions,
      allowedPermissions: [JobPermission.UPDATE],
    })
    if (!form.getValues('name') && viewedJob) {
      const { client, ...job } = viewedJob
      // @ts-expect-error
      reset({
        ...job,
        ...transformValues(viewedJob),
      })
    }
    setIsUpdatable(canUpdateJob)
  }, [viewedJob, orgUserOptions])

  useEffect(() => {
    if (!clientValue && orgUserOptions && viewedJob.client) {
      // @ts-expect-error
      form.setValue('client', orgUserOptions ? orgUserOptions.find((option) => option.id === viewedJob.client) ?? null : null)
    }
  }, [clientValue, orgUserOptions, viewedJob.client])

  useEffect(() => {
    if (!isEditing && isDirty) {
      // @ts-expect-error
      reset({
        ...job,
        ...transformValues(viewedJob),
      })
    }
  }, [isEditing, isDirty, reset, job, viewedJob, transformValues])

  const assignUsers = () => {
    const userPermissionModalOptions: ModalOptions = {
      modalProps: {
        [ModalPages.USERPERMISSION]: {
          job: viewedJob,
        },
      },
    }
    toggleModal(ModalPages.USERPERMISSION, userPermissionModalOptions)
    trackEvent({ event: TrackingEventEnums.Job.JOB_ADD_USER, eventProperties: { workspace } })
  }

  const onSubmit = (data: tJobDetailsForm) => {
    return new Promise((resolve, reject) => {
      let changes = Object.fromEntries(Object.keys(dirtyFields).map((key: any) => [key, data[key as keyof Types.Job.iJobPayload]]))

      if (changes.job_currency_code) {
        changes = { ...changes, job_currency_code: changes.job_currency_code.value }
      }
      if (changes.event_types) {
        const { event_types, ...newChanges } = changes
        changes = { ...newChanges, event_type_eids: (event_types as Types.Job.iJobPayload['event_types'])?.map(({ eid }) => eid) }
      }
      if (changes.organisation) {
        const { organisation, ...newChanges } = changes
        changes = { ...newChanges, organisation_eid: organisation.eid }
      }
      if (changes.department) {
        const { department, ...newChanges } = changes
        changes = { ...newChanges, department_eid: department.eid }
      }
      if (changes.client) {
        const { client, ...newChanges } = changes
        changes = { ...newChanges, client: client.id }
      }
      if (changes.custom_fields) {
        changes = { ...changes, custom_fields: decodeCustomFieldsValue(changes.custom_fields) }
      }
      if (changes.description) {
        changes = { ...changes, plain_description: data.plain_description }
      }
      if (changes.date_type) {
        changes = { ...changes, date_type: data.date_type?.toUpperCase() }
      }

      updateJob(
        { jobEid: viewedJob.eid, payload: changes },
        {
          onSuccess: (data) => {
            setIsEditing(false)
            queryClient.invalidateQueries({
              queryKey: ['notification-unread-count'],
              refetchType: 'all', // refetch both active and inactive queries
            })
            resolve(data)
          },
          onError: (error) => {
            toast.error('Failed to update job')
            reject(error)
          },
        }
      )
    })
  }

  const publishLive = () => {
    if (isDraft && viewedJob.status !== 'LIVE' && isDirty) {
      toggleModal(ModalPages.CONFIRM_SAVE_PUBLISH, {
        modalProps: {
          [ModalPages.CONFIRM_SAVE_PUBLISH]: {
            saveAndPublish: ({ setIsLoading, close }: { setIsLoading: (val: boolean) => void; close: () => void }) => {
              setIsLoading(true)
              onSubmit(form.getValues()).then(() => {
                handleSetJobLive(() => {
                  setIsLoading(false)
                  close()
                })
              })
            },
          },
        },
      })
    } else {
      handleSetJobLive()
    }
  }
  // End of hook form actions

  const toggleJobDetails = () => {
    setShowDetails((prev) => {
      trackEvent({
        event: !prev ? TrackingEventEnums.Job.JOB_SHOW_DETAILS : TrackingEventEnums.Job.JOB_HIDE_DETAILS,
        eventProperties: { workspace, jobEid: viewedJob.eid },
      })
      return !prev
    })
  }

  return (
    <FormProvider {...form}>
      <DevTool control={control} />
      <form>
        <Stack direction="row" justifyContent="flex-end" marginBottom="12px">
          <Stack direction="row" alignItems="center" spacing="20px">
            <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.UPDATE]}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  gap: '5px',
                }}
                onClick={doSubmit(onSubmit)}
                type="button"
                disabled={!isDirty || updateJobStatus === 'pending'}>
                <Icon.Save />
                {isDraft ? 'Save Draft' : 'Save Changes'}
              </Button>
            </PermissionWrapper>
            <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.PUBLISH]}>
              <>
                {isDraft && (
                  <Button variant="contained" sx={{ fontSize: '14px', gap: '5px' }} onClick={publishLive}>
                    <Icon.CheckSquare />
                    Publish
                  </Button>
                )}
              </>
            </PermissionWrapper>
            <PermissionWrapper
              checkMethod={'every'}
              permissions={viewedJob.permissions}
              allowedPermissions={[JobPermission.COMPLETE, JobPermission.UPDATE]}>
              <ActionMenu disabled={!!selectedDeliverableEid} />
            </PermissionWrapper>
          </Stack>
        </Stack>
        {viewedJobStatus !== 'success' ? (
          <Box height="90vh">{false ? <JobNotFound /> : <Loader />}</Box>
        ) : (
          <DrawerContext.Provider value={{ isOpen: !!selectedDeliverableEid }}>
            <hasUpdatePermissionsContext.Provider value={{ isUpdatable, setIsUpdatable: setIsUpdatable }}>
              <EditIconProvider>
                <form>
                  <Stack rowGap="10px">
                    <Paper className="paper-border" rowGap="10px" style={{ borderRadius: '6px' }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="14px"
                        padding="12px 24px"
                        onMouseEnter={() => setVisibleEditIcon('name')}
                        onMouseLeave={() => setVisibleEditIcon(null)}>
                        <Button
                          aria-label="back to jobs"
                          onClick={() => goToRequests()}
                          variant="outlined"
                          sx={{
                            border: `1px solid ${GlobalStyles.SLATE_100}`,
                            fontSize: '16px',
                            gap: '5px',
                          }}>
                          <Icon.ArrowLeft width={16} />
                        </Button>
                        {isEditing && isUpdatable ? (
                          <Textfield
                            name="name"
                            variant="standard"
                            placeholder="job name"
                            sx={{
                              width: '300px',
                              '.MuiInputBase-input.MuiInput-input': {
                                fontSize: '24px',
                              },
                            }}
                          />
                        ) : (
                          <Typography
                            onClick={() => (isUpdatable ? setIsEditing(true) : null)}
                            variant="h1"
                            color={GlobalStyles.MONO_BLACK}
                            fontSize={`${GlobalStyles.FONT_SIZES.XTRA_LARGE}px`}
                            fontWeight={500}>
                            {viewedJob.name}
                          </Typography>
                        )}
                        {viewedJob.status && (
                          <Chip
                            size="small"
                            label={capitalize(viewedJob.status.toLowerCase())}
                            style={STATUS_CHIP_STYLES(viewedJob.status)}
                            sx={{ span: { padding: '2px 8px' } }}
                          />
                        )}
                        {isUpdatable && (
                          <Button
                            aria-label={isEditing ? 'cancel edit job' : 'edit job'}
                            onClick={() => setIsEditing((prev) => !prev)}
                            variant="outlined"
                            sx={{
                              marginLeft: 'auto',
                              border: `1px solid ${GlobalStyles.SLATE_100}`,
                              fontSize: '16px',
                              gap: '5px',
                            }}>
                            {isEditing ? <Icon.Slash width={16} /> : <Icon.Edit2 width={16} />}
                          </Button>
                        )}
                      </Stack>
                      <Row hasBorders={false}>
                        <CommonGrid label="Job ID" tooltipText="" value={viewedJob.eid} gridProps={{ sx: { padding: '12px 24px' } }} />
                        <CommonGrid
                          label="Job Owner(s)"
                          tooltipText="The main point of contact and coordinator of this job. All communication regarding job details should be directed to this person."
                          gridProps={{ sx: { padding: '12px 24px' } }}
                          value={
                            <Box display="flex" alignItems="center">
                              <AvatarGroup offset={4} extraAvatarProps={{ size: 'xs' }}>
                                {[...(viewedJobUsers?.users.filter((item) => item.ownership_type == 'OWNER') ?? [])].map((user) => {
                                  return (
                                    <Tooltip
                                      key={user.eid}
                                      text={user.name}
                                      showIcon={false}
                                      {...(viewedJobUsers?.users.length === 1
                                        ? {
                                            direction: 'row',
                                            display: 'flex',
                                            columnGap: '10px',
                                          }
                                        : {})}>
                                      <>
                                        <Avatar
                                          key={user.eid}
                                          size="xs"
                                          image={user.profile_image?.full_url ?? undefined}
                                          placeHolder={
                                            <Icon.User
                                              size={GlobalStyles.AVATAR_SIZES.XS - 5}
                                              color={GlobalStyles.SLATE_200}
                                              strokeWidth={1}
                                            />
                                          }
                                        />
                                        {viewedJobUsers?.users.length === 1 && <Typography>{user.name}</Typography>}
                                      </>
                                    </Tooltip>
                                  )
                                })}
                              </AvatarGroup>
                            </Box>
                          }
                        />
                      </Row>
                    </Paper>
                    <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.DETAILS]}>
                      <Paper className="paper-border" paddingBottom={0} style={{ borderRadius: '6px' }}>
                        {showDetails ? (
                          <>
                            <Row>
                              <CommonGrid
                                label="Users"
                                tooltipText="Users who are helping to coordinate and organise the job"
                                value={
                                  <Box display="flex" alignItems="center">
                                    <QuickLook
                                      data={{
                                        Users: [...(viewedJobUsers?.users ?? []), ...(viewedJobUsers?.invites ?? [])],
                                      }}>
                                      <Box>
                                        <AvatarGroup offset={3} extraAvatarProps={{ size: 'xs' }}>
                                          {[...(viewedJobUsers?.users ?? []), ...(viewedJobUsers?.invites ?? [])].map((user) => (
                                            <Avatar
                                              key={user.eid}
                                              size="xs"
                                              image={user.profile_image?.full_url ?? undefined}
                                              placeHolder={
                                                <Icon.User
                                                  size={GlobalStyles.AVATAR_SIZES.XS - 5}
                                                  color={GlobalStyles.SLATE_200}
                                                  strokeWidth={1}
                                                />
                                              }
                                            />
                                          ))}
                                        </AvatarGroup>
                                      </Box>
                                    </QuickLook>
                                    <Button
                                      disabled={!canAddUsers}
                                      variant="text"
                                      sx={{ fontSize: '14px', gap: '5px' }}
                                      onClick={assignUsers}>
                                      <Icon.PlusCircle width={16} height={16} />
                                      Add User
                                    </Button>
                                  </Box>
                                }
                              />
                              <CommonGrid
                                label="Department"
                                value={
                                  <FormAutoComplete
                                    name="department"
                                    getOptionLabel={(option) => option.name}
                                    disabled={!isUpdatable}
                                    textfieldProps={{
                                      placeholder: 'Department',
                                      onChange: (e) => departmentsLoadOnScroll.searchOption(e.target.value),
                                    }}
                                    options={departmentOptions}
                                    loading={!departmentOptions.length && departmentsLoadOnScroll.isLoading}
                                    isOptionsLoading={departmentsLoadOnScroll.isLoading}
                                    onScrollToBottom={() => departmentsLoadOnScroll.loadMore()}
                                  />
                                }
                              />
                            </Row>
                            <Row>
                              <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.CLIENT]}>
                                <CommonGrid
                                  label="Client Contact"
                                  tooltipText="Only internal users will see this"
                                  value={
                                    <FormAutoComplete
                                      hasAdd
                                      name="client"
                                      textfieldProps={{ placeholder: 'Client contact' }}
                                      options={orgUserOptions}
                                      getOptionLabel={(option) => option.name}
                                      disabled={!isUpdatable}
                                    />
                                  }
                                />
                              </PermissionWrapper>
                              <CommonGrid
                                label="Organisation"
                                value={
                                  <FormAutoComplete
                                    name="organisation"
                                    options={organisationOptions}
                                    getOptionLabel={(option) => option.name}
                                    disabled={!isUpdatable}
                                    textfieldProps={{
                                      placeholder: 'Organisation',
                                      onChange: (e) => searchOrgOptions(e.target.value),
                                    }}
                                    loading={!organisationOptions.length && organisationOptionsLoads}
                                    isOptionsLoading={organisationOptionsLoads}
                                    onScrollToBottom={() => loadMoreOrgOptions()}
                                  />
                                }
                              />
                            </Row>
                            <Row>
                              <CommonGrid
                                label="Event Type"
                                tooltipText="What event is being organised as part of this job? This gives stars valuable context, and will help with your reporting"
                                value={
                                  <FormAutoComplete
                                    multiple
                                    name="event_types"
                                    options={eventTypeOptions}
                                    getOptionLabel={(option) => option.name}
                                    disabled={!isUpdatable}
                                  />
                                }
                              />
                              <CommonGrid
                                label="Location"
                                tooltipText="This is a list of all locations your deliverables are based in"
                                value={
                                  isEditing && isUpdatable ? (
                                    features.isGoogleMapsApiEnabled ? (
                                      <FormAutoCompleteLocation
                                        name="location"
                                        onChange={locationChange}
                                        defaultValue={formatLocation(viewedJob.location)}
                                      />
                                    ) : (
                                      <Input {...register('location.address_1')} placeholder="location" />
                                    )
                                  ) : (
                                    formatLocation(viewedJob.location)
                                  )
                                }
                              />
                            </Row>
                            {features.isSocialCampaignsEnabled && (
                              <>
                                <Campaign />
                                <SocialCampaign />
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <Row>
                          <CommonGrid
                            label="Job Date Guide"
                            tooltipText="Date range gives stars an indication of when your job may be occuring. All deliverables must be in this range"
                            value={
                              <Stack direction="row" alignItems="center" columnGap="8px" flexWrap={'wrap'}>
                                {isEditing && isUpdatable ? (
                                  dateTypeValue === 'FIXED' ? (
                                    <FormDateTimePicker
                                      format={workspaceSettings?.date_format}
                                      defaultValue={startDateValue ? moment(startDateValue) : null}
                                      onChange={(date) => {
                                        startDateOnChange(date?.format(API_DATE_FORMAT))
                                        endDateOnChange(`${date?.format('YYYY-MM-DD')} 23:59:59`)
                                      }}
                                    />
                                  ) : (
                                    <FormDateRangePicker
                                      defaultValue={[
                                        startDateValue ? moment(startDateValue) : null,
                                        endDateValue ? moment(endDateValue) : null,
                                      ]}
                                      onChange={([startDate, endDate]) => {
                                        startDateOnChange(startDate?.format(API_DATE_FORMAT))
                                        endDateOnChange(endDate?.format(API_DATE_FORMAT))
                                      }}
                                    />
                                  )
                                ) : (
                                  <Typography
                                    color={GlobalStyles.SLATE_700}
                                    fontSize="14px"
                                    sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                    {getDate(startDateValue)}{' '}
                                    {getDate(endDateValue) && dateTypeValue !== 'FIXED' && (
                                      <>
                                        <Icon.ArrowRight width={16} /> {getDate(endDateValue)}
                                      </>
                                    )}
                                  </Typography>
                                )}

                                {isUpdatable && (
                                  <Stack>
                                    <FormSearchTagV2
                                      name="date_type"
                                      secondary
                                      noSearch
                                      disabled={!isUpdatable || !isEditing}
                                      options={GUIDE_TYPE_OPTIONS}
                                      onSelect={(item) => dateTypeOnChange(item.id)}
                                      defaultValue={GUIDE_TYPE_OPTIONS.find((item) => item.id === dateTypeValue) ?? GUIDE_TYPE_OPTIONS[0]}
                                    />
                                  </Stack>
                                )}
                              </Stack>
                            }
                          />
                          <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.COST]}>
                            <Grid
                              item
                              xxs={12}
                              md={!!selectedDeliverableEid ? 12 : 6}
                              xl={6}
                              display="flex"
                              padding="12px 24px"
                              alignItems="center"
                              width="100%"
                              sx={{
                                borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
                              }}>
                              <Stack
                                flex={1}
                                direction="row"
                                alignItems="center"
                                justifyContent="start"
                                onMouseEnter={() => setVisibleEditIcon('cost')}
                                onMouseLeave={() => setVisibleEditIcon(null)}>
                                <Stack direction="row" alignItems="center" columnGap="8px" flex={1}>
                                  <Stack direction="row" gap="4px" minWidth="121px">
                                    <ColumnLabel
                                      label={costModeValue === 'PER_JOB' ? 'Job Cost' : 'Total Job Cost'}
                                      tooltipText="The budget is a guide for how much you would like to spend. This will help you track your bookings against your goal."
                                    />
                                  </Stack>
                                  <Stack direction="row" alignItems="center" columnGap="8px" flexWrap={{ xxs: 'wrap', lg: 'nowrap' }}>
                                    {isEditing && isUpdatable && viewedJob.cost_mode === 'PER_JOB' ? (
                                      <CurrencyTextfield
                                        currency={{
                                          type: 'number',
                                          name: 'job_cost',
                                        }}
                                        currencyCode={{ name: 'job_currency_code', options: currenyCodeOptions }}
                                      />
                                    ) : (
                                      <Typography color={GlobalStyles.SLATE_700} fontSize="14px">
                                        {formatCurrency(
                                          viewedJob.job_currency_code ?? workspaceSettings?.default_currency,
                                          viewedJob.job_cost
                                        )}
                                      </Typography>
                                    )}
                                    {viewedJob.cost_mode === 'PER_JOB' && (
                                      <SearchTagV2
                                        secondary
                                        noSearch
                                        disabled={!isUpdatable}
                                        options={COST_MODE_OPTIONS}
                                        onSelect={(val) => {
                                          trackEvent({
                                            event: TrackingEventEnums.Job.JOB_COST_MODE_SELECTED,
                                            eventProperties: {
                                              page_name: 'job details page',
                                              component_name: 'Job Cost',
                                              workspace: workspace,
                                              cost_mode: val.id,
                                            },
                                          })
                                          costModeOnChange(val.id)
                                        }}
                                        defaultValue={COST_MODE_OPTIONS.find((item) => item.id === costModeValue) || COST_MODE_OPTIONS[0]}
                                        style={{ minWidth: '130px' }}
                                      />
                                    )}
                                  </Stack>
                                </Stack>
                                {/* </PermissionWrapper> */}
                                {isUpdatable && (
                                  <Icon.Edit2
                                    size={16}
                                    color={GlobalStyles.PRIMARY_500}
                                    style={{
                                      marginLeft: 'auto',
                                      visibility: visibleEditIcon === 'cost' && !isEditing ? 'visible' : 'hidden',
                                    }}
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </PermissionWrapper>
                        </Row>
                        {showDetails ? (
                          <Row>
                            <CommonGrid
                              gridProps={{ md: 12, xl: 12 }}
                              label="Description"
                              tooltipText="Add extra details that talent will need to know in order to fullfill this deliverable/job."
                              alignItems="baseline"
                              value={
                                isEditing && isUpdatable ? (
                                  <RichEditor
                                    initialValue={descriptionValue ?? ''}
                                    onChange={(value, plaintext) => {
                                      descriptionOnChange(value)
                                      form.setValue('plain_description', plaintext)
                                    }}
                                    showInlineSendButton={false}
                                  />
                                ) : (
                                  (viewedJob.description || viewedJob.description) && (
                                    <RichReader initialValue={viewedJob.description ?? ''} />
                                  )
                                )
                              }
                            />
                          </Row>
                        ) : (
                          <></>
                        )}
                        <Row hasBorders={false}>
                          <Stack width="100%">
                            <Button variant="text" sx={{ fontSize: '14px' }} onClick={toggleJobDetails}>
                              {showDetails ? 'Hide' : 'Show'} Details
                            </Button>
                          </Stack>
                        </Row>
                      </Paper>
                    </PermissionWrapper>
                  </Stack>
                </form>
              </EditIconProvider>
            </hasUpdatePermissionsContext.Provider>
          </DrawerContext.Provider>
        )}
      </form>
    </FormProvider>
  )
}
